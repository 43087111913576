import React, { useRef, useState, useEffect, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  faArrowLeft,
  faPrint,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapRender from "./MapRender/MapRender";

import { GeoJSON } from "ol/format";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import { Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { Fill, Stroke, Style } from "ol/style.js";
import { getCenter } from "ol/extent";

import logo from "../../../../assets/img/logo_ita.png";
import daclogo from "../../../../assets/img/logo.png";
import image from "../../../../assets/img/image.png";
import "./ResultPage.css";

import InconsistenciaServices from "../../../../services/inconsistenciaServices";

proj4.defs(
  "EPSG:31983",
  "+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

function ResultPage({ selectedResult, setSelectedResult }) {
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const componentRef = useRef();
  function handleClickPrint() {
    // setPrint((prevState) => !prevState);
    handlePrint();
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [valuesLat, setValuesLat] = useState([0, 0, 0, 0]);
  const [valuesLon, setValuesLon] = useState([0, 0, 0, 0, 0, 0]);

  const inconsistenciaService = new InconsistenciaServices();

  useEffect(() => {
    if (!selectedResult) return;

    const id = selectedResult.id;

    setLoading(true);

    inconsistenciaService.getInconsistencia(id).then((data) => {
      if (typeof data !== "object") {
        setResult(undefined);
        setLoading(false);
        return;
      }

      setResult(data);
      setLoading(false);
    });
  }, [selectedResult]);

  return (
    <div className="result">
      <div className="actions">
        <button
          className="action-button"
          onClick={() => setSelectedResult(undefined)}
          style={{
            marginRight: "auto",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          Voltar
        </button>
        {!loading && result && (
          <button
            className="action-button"
            onClick={handleClickPrint}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faPrint} />
            Imprimir
          </button>
        )}
      </div>

      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "100px 0px",
          }}
        >
          <FontAwesomeIcon size="3x" icon={faSpinner} color="#1976d2" spin />
        </div>
      )}

      {!loading && result && (
        <ComponentToPrint
          ref={componentRef}
          result={result}
          valuesLat={valuesLat}
          setValuesLat={setValuesLat}
          valuesLon={valuesLon}
          setValuesLon={setValuesLon}
          print={print}
        />
      )}

      {!loading && !result && (
        <div className="result-content">
          <h1
            className="title"
            style={{
              color: "red",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Erro ao carregar o resultado
          </h1>
          <p
            className="description"
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            Houve um erro ao carregar o resultado. Por favor, tente novamente.
          </p>
        </div>
      )}
    </div>
  );
}

const ComponentToPrint = forwardRef((props, ref) => {
  const { result, print } = props;

  const pad = (number, digits) => {
    if (number === null) return;

    if (typeof number === "number") number = number.toString();
    if (number.length >= digits) return number;

    const newNumber = "0".repeat(digits - number.length) + number;
    return newNumber;
  };

  const date = new Date();
  const today = `${
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  }/${
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getFullYear()} ${
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  }:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;

  const inscricao = `01.${pad(result.loteNovo.setorCod, 2)}.${pad(
    result.loteNovo.quadraCod,
    3
  )}.${pad(result.loteNovo.loteCod, 4)}${
    result.loteNovo.unidade ? "." + pad(result.loteNovo.unidade, 3) : ""
  }`;

  // Texto base inicial
  let text = `
  Notificamos a atualização do cadastro imobiliário do Município de Itajubá por meio de técnicas de geoprocessamento. 
  Após a atualização, `;

  let detalhesAdicionais = [];

  if (result.inconsistencias.isAreaTerreno) {
    detalhesAdicionais.push("houve uma alteração na área do terreno");
  }

  if (result.inconsistencias.isAreaConstrucao) {
    detalhesAdicionais.push("houve uma alteração na área construída");
  }

  if (result.inconsistencias.isNovaUnidade) {
    detalhesAdicionais.push("foi identificada uma nova unidade no imóvel");
  }

  if (result.inconsistencias.isDemolicao) {
    detalhesAdicionais.push(
      "foi detectada a demolição de uma unidade no imóvel"
    );
  }

  if (result.inconsistencias.isNovaInscricao) {
    detalhesAdicionais.push("foi gerada uma nova inscrição para o imóvel");
  }

  if (detalhesAdicionais.length > 0) {
    const ultimosDetalhes =
      detalhesAdicionais.length > 1
        ? detalhesAdicionais.slice(0, -1).join(", ") +
          " e " +
          detalhesAdicionais[detalhesAdicionais.length - 1]
        : detalhesAdicionais[0];

    text += ` ${ultimosDetalhes} do imóvel nº ${inscricao}.`;
  }

  const handleList = (inconsistencias) => {
    const list = [];

    if (inconsistencias.isAreaTerreno) {
      list.push("Área do terreno");
    }

    if (inconsistencias.isAreaConstrucao) {
      list.push("Área construída");
    }

    if (inconsistencias.isNovaUnidade) {
      list.push("Nova unidade de construção");
    }

    if (inconsistencias.isDemolicao) {
      list.push("Demolição");
    }

    return list.join(" / ");
  };

  text += ` Caso o proprietário discorde das informações apresentadas, deverá apresentar documentos comprobatórios no Setor de Cadastro Imobiliário, localizado na Secretaria de Obras, ou enviar os documentos para o e-mail semob@itajuba.mg.gov.br no prazo de 30 (trinta) dias para análise. Após a análise dos documentos, o proprietário será informado da decisão no prazo de 30 (trinta) dias.`;

  console.log(result);

  return (
    <div className="page" ref={ref}>
      <div className="page-header">
        <div className="description">
          <p className="text">PREFEITURA MUNICIPAL DE ITAJUBÁ</p>
          <p className="text">Emissão: {today}</p>
        </div>

        <div className="content">
          <img src={logo} alt="logo" width="80" height="80" />
          <div className="title">
            <h1 className="text">PREFEITURA MUNICIPAL DE ITAJUBÁ</h1>
            <h2 className="text">ESTADO DE MINAS GERAIS</h2>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="information">
          <div className="top">
            <p className="text">Dados Gerais</p>
            <p className="text">NOTIFICAÇÃO</p>
          </div>

          <div className="content">
            <div>
              <p className="text">INSCRIÇÃO</p>
              <p className="text">{inscricao}</p>
            </div>
            <div>
              <p className="text">PROPRIETÁRIO</p>
              <p className="text">
                {result?.proprietario.nome ?? "Não informado"}
              </p>
            </div>
            <div>
              <p className="text">LOGRADOURO</p>
              <p className="text">
                {result?.logradouros && result?.logradouros.length > 0
                  ? result?.logradouros[0].nome
                  : "Não informado"}
              </p>
            </div>
            <div>
              <p className="text">NÚMERO</p>
              <p className="text">
                {result?.endereco.numero ?? "Não informado"}
              </p>
            </div>
          </div>
        </div>

        <div className="description">
          <p className="text">
            Prezado(a) Sr(a). <b>{result?.proprietario.nome}</b>,
          </p>

          <p className="text">{text}</p>
        </div>

        <div className="values">
          <p className="text">{handleList(result.inconsistencias)}</p>

          {result.inconsistencias.isAreaTerreno ||
          result.inconsistencias.isAreaConstrucao ? (
            <div className="content">
              <div>
                <p className="text">Área Antiga</p>
                <p className="text">
                  {`${
                    result.inconsistencias.isAreaTerreno
                      ? result.loteAntigo.areaTerreno ?? "N/A"
                      : result.inconsistencias.isAreaConstrucao
                      ? result.loteAntigo.areaConstrucao ?? "N/A"
                      : "N/A"
                  } m²`}
                </p>
              </div>
              <span></span>
              <div>
                <p className="text">Área Nova</p>
                <p className="text">
                  {`${
                    result.inconsistencias.isAreaTerreno
                      ? result.loteNovo.areaTerreno ?? "N/A"
                      : result.inconsistencias.isAreaConstrucao
                      ? result.loteNovo.areaConstrucao ?? "N/A"
                      : "N/A"
                  } m²`}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="preview">
          <div className="title">
            <img src={logo} alt="logo" width="40" height="40" />

            <div className="text">
              <p className="text">Planta de Situação</p>
              <p className="text">Lote {result.loteNovo.loteCod}</p>
            </div>
          </div>

          <div className="maps">
            <div className="map">
              <div className="values">
                <p className="subtitle">{props.valuesLat[0]}</p>
                <p className="subtitle">{props.valuesLat[1]}</p>
                <p className="subtitle">{props.valuesLat[2]}</p>
                <p className="subtitle">{props.valuesLat[3]}</p>
              </div>
              <div className="values">
                <p className="subtitle">{props.valuesLon[0]}</p>
                <p className="subtitle">{props.valuesLon[1]}</p>
                <p className="subtitle">{props.valuesLon[2]}</p>
                <p className="subtitle">{props.valuesLon[3]}</p>
                <p className="subtitle">{props.valuesLon[4]}</p>
                <p className="subtitle">{props.valuesLon[5]}</p>
              </div>
              <MapRender
                result={result}
                number="1"
                setValuesLat={props.setValuesLat}
                setValuesLon={props.setValuesLon}
                print={print}
              />
            </div>
            <div className="map">
              <MapRender result={result} number="2" print={print} />
            </div>
            <div className="map">
              <MapRender result={result} number="3" print={print} />
            </div>
          </div>

          <div className="footer">
            <div className="information">
              {/* <img src={image} alt="image" width="150" /> */}

              <div className="logos">
                <img src={logo} alt="logo" width="40" />
                <img src={daclogo} alt="logo" width="100" />
              </div>
              <h3 className="text">Legenda</h3>
              <div
                style={{
                  width: "100%",
                  border: "1px solid black",
                  padding: "2px",
                  textAlign: "justify",
                }}
              >
                <ul>
                  <li>
                    <span
                      style={{
                        background: "rgba(254,234,55,0.5)",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Lote
                  </li>
                  <li>
                    <span
                      style={{
                        background: "rgba(128,128,128,0.3)",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Cobertura
                  </li>
                  <li>
                    <span
                      style={{
                        background: "blue",
                        display: "inline-block",
                        width: "2px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Testada
                  </li>
                  <li>
                    <span
                      style={{
                        background: "rgba(233,0,172,0.2)",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Lotes da quadra
                  </li>
                  <li>
                    <span
                      style={{
                        background: "#e966ac",
                        display: "inline-block",
                        width: "2px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Quadra
                  </li>
                </ul>
              </div>
            </div>

            <div className="information">
              <p className="text">Datum SIRGAS 2000</p>
              <p className="text">Projeção Cartográfica UTM 23S</p>
              <p className="text">Base cartográfica de Abril de 2023</p>
            </div>

            <div className="information">
              <p className="text">
                Endereço:{" "}
                {result.logradouros && result.logradouros.length > 0
                  ? result.logradouros[0].nome
                  : "Não informado"}
                , {result.endereco.numero ?? "Não informado"}
              </p>
              <p className="text">
                Reduzido das unidades imobiliárias: {result.loteNovo.loteCod}
              </p>

              <div className="comparison">
                <div className="data">
                  <p className="text">Dados 2024</p>

                  <p className="text">Reduzido: {result.loteAntigo.loteCod}</p>
                  <p className="text">
                    Distrito: {result.loteAntigo.distritoCod}
                  </p>
                  <p className="text">Setor: {result.loteAntigo.setorCod}</p>
                  <p className="text">Quadra: {result.loteAntigo.quadraCod}</p>
                  <p className="text">Lote: {result.loteAntigo.loteCod}</p>
                </div>

                <div className="data">
                  <p className="text">Dados Novos</p>

                  <p className="text">Reduzido: {result.loteNovo.loteCod}</p>
                  <p className="text">
                    Distrito: {result.loteNovo.distritoCod}
                  </p>
                  <p className="text">Setor: {result.loteNovo.setorCod}</p>
                  <p className="text">Quadra: {result.loteNovo.quadraCod}</p>
                  <p className="text">Lote: {result.loteNovo.loteCod}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ResultPage;
