import { Feature } from "ol";
import { MultiPolygon, Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import { getArea } from "ol/sphere"; // Corrigido para usar getArea()

export function fnAddLabel(source, id, color = "#0000ff") {
  // Obtendo o centro da extensão do source
  const coordCenter = source.getExtent();
  const center = [
    (coordCenter[0] + coordCenter[2]) / 2,
    (coordCenter[1] + coordCenter[3]) / 2,
  ];
  const idLabel = String(id); // Convertendo o id para string

  // Criando a camada com o texto da área
  const label = new VectorLayer({
    source: new VectorSource({
      features: [
        new Feature({
          geometry: new Point(center),
          name: idLabel, // Exibindo a área com 2 casas decimais
        }),
      ],
    }),
    style: new Style({
      text: new Text({
        text: idLabel, // Exibindo a área com 2 casas decimais
        font: "18px Calibri, sans-serif",
        fill: new Fill({
          color: color,
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 3,
        }),
      }),
    }),
  });

  return label;
}
