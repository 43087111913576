import { Button, Grid } from "@mui/material";
import { RecipeReviewCard } from "./componentsImage/RecipeReviewCard";
import UploadComponent from "./componentsImage/uploadComponent";
import { Api } from "../../../../../services/Api";
import { Component } from "react";
import { UplaodsInit } from "./componentsImage/UplaodsInit";

export class TabImagens extends Component {
  constructor(props) {
    super(props);
    this.state = { imagemTemporaria: [], uploads: [], initImagens: [] };
  }

  // Método para buscar imagens do AWS
  fetchImagensAWS = async (id) => {
    try {
      const imagemAWS = await Api.getImagensStreetView(id);
      const imagemTemporaria = imagemAWS[0].images.map((item) => item);
      this.setState({ imagemTemporaria }); // Armazena a imagem temporária no estado
    } catch (error) {
      console.error("Erro ao buscar a imagem do AWS:", error);
    }
  };

  componentDidMount() {
    // Chamada inicial para buscar imagens ao montar o componente
    this.fetchImagensAWS(this.props.id);
  }

  componentDidUpdate(prevProps) {
    // Verifica se props.dados mudou
    if (prevProps.dados !== this.props.dados) {
      const initImagens = this.props.dados
      this.setState({ initImagens })
    }
  }

  atualizarDadosImagens = () => {
    const { onUpdate } = this.props;
    if (onUpdate) {
      const imagensSelecionadas = this.state.imagemTemporaria.filter(
        (imagem) => imagem.selected === true
      );
      const imagensInfo = imagensSelecionadas.map((imagem) => ({
        imagemId: imagem.imagemId,
        nome: imagem.nome_imagem,
        angulo: imagem.angle,
        path: imagem.path,
      }));
      onUpdate(imagensInfo);
    }
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const novasImagens = files.map((file) => ({
      id: Date.now() + Math.random(),
      file: file,
      url: URL.createObjectURL(file),
    }));

    this.setState({ imagemTemporaria: novasImagens });
  };

  handleDeleteImagem = (imagemDeletada) => {
    this.setState((prevState) => {
      const imagemTemporariaAtualizada = prevState.imagemTemporaria.map(
        (imagem) =>
          imagem.nome_imagem === imagemDeletada.nome_imagem
            ? { ...imagem, selected: false }
            : imagem
      );
      return { imagemTemporaria: imagemTemporariaAtualizada };
    });
    this.atualizarDadosImagens();
  };

  salvarImagens = () => {
    const imagensSelecionadas = this.state.imagemTemporaria.filter(
      (imagem) => imagem.selected === true
    );

    console.log("Imagens para salvar:", imagensSelecionadas);
  };

  handleAddImage = (imagem) => {
    this.setState((prevState) => ({
      imagemTemporaria: prevState.imagemTemporaria.map((img) =>
        img.nome_imagem === imagem.nome_imagem
          ? { ...img, selected: true }
          : img
      ),
    }));
    this.atualizarDadosImagens();
  };

  saveUploads = async () => {
    const arrayImagemUpload = this.state.initImagens.map(item => item.url)
    const img = [...this.state.uploads]

    const aux = await Api.postTestUpload(img, this.props.id, 10);
    if (aux?.arquivos?.arquivos.length > 0)
      alert('Uploads enviados com sucesso')
    else {
      alert('falha ao enviar uploads')
    }
  }

  removeUpload = (id) => {
    this.setState((prevState) => ({
      initImagens: prevState.initImagens.filter((upload) => upload.id !== id),
    }));
  };

  sendUploads = (img) => {
    this.setState({ uploads: img });
  };

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: 'start' }}>
        <Grid container spacing={2} sx={{ paddingLeft: 0 }}>
          <UploadComponent sendUploads={this.sendUploads} />
          <UplaodsInit images={this.state.initImagens} removeUpload={this.removeUpload} />
        </Grid>
        <Button onClick={this.saveUploads}>Enviar Uploads</Button>

        <Grid container spacing={2} sx={{ paddingLeft: 0 }}>
          {this.state.imagemTemporaria.map((imagem, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`${imagem.nome_imagem}_${index}`}
              sx={{ height: 150 }}
            >
              <RecipeReviewCard
                inviteStreetView={this.props.onClickImage}
                imagem={imagem}
                onDelete={() => this.handleDeleteImagem(imagem)}
                onAdd={() => this.handleAddImage(imagem)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}
