import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Fab, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFloppyDisk,
  faPen,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/Api";
import ModalPontoInteresse from "./ModalPontoInteresse/ModalPontoInteresse";
import ModalEdificacao from "./ModalEdificacao/ModalEdificacao";

import ManegerVectorLayer from "../../../Map/Component/VectorManager";
import { configurarCoordenadaCentral } from "../../../Map/Hooks/FormaterCoordinates";
import { PanoramaImoveis } from "./PanoramaImoveis";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InfoImoveis({ respostaApi, onBack }) {
  const [value, setValue] = React.useState(0);
  const [searching, setSearching] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);
  const [modalEdificacaoAberto, setModalEdificacaoAberto] = useState(false);
  const [editarProprietario, setEditarProprietario] = useState(false);
  const dataApi = respostaApi?.data?.result || null;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [inputs, setInputs] = useState({
    proprietarioNome: dataApi?.proprietario?.nome || null,
    proprietarioCpfCnpj: dataApi?.proprietario?.cpfCnpj || null,

    //lote
    //obrigatorio
    loteId: dataApi?.lote?.id || null,
    loteAreaTerreno: dataApi?.lote?.areaTerreno || null,
    loteAreaTerrenoIsento: dataApi?.lote?.areaTerrenoIsento || 0,
    loteAreaConstrucao: dataApi?.lote?.areaConstrucao || 0,
    loteFracaoIdeal: dataApi?.lote?.fracaoIdealInfo || 0,
    //opcional    
    loteTopografia: dataApi?.lote?.topologia || ' ',
    lotePedologia: dataApi?.lote?.pedologia || ' ',
    loteNivelacao: dataApi?.lote?.nivelacao || ' ',
    loteTipo: dataApi?.lote?.tipo || ' ',
    loteSegmento: dataApi?.lote?.segmento || ' ',
    loteLado: dataApi?.lote?.lado || ' ',
    loteOcupacao: dataApi?.lote?.ocupacao || ' ',
    loteUtilizacao: dataApi?.unidade?.utilizacao || ' ',
    lotePatrimonio: dataApi?.lote?.patrimonio || ' ',
    loteFinalidade: dataApi?.lote?.finalidade || ' ',
    loteMuro: dataApi?.lote?.muro || ' ',
    lotePasseio: dataApi?.lote?.passeio || ' ',
    loteFrentes: dataApi?.lote?.frentes || ' ',
    loteArvore: dataApi?.lote?.arvore || ' ',
    loteFormato: dataApi?.lote?.formato || ' ',
    lotePredial: dataApi?.lote?.predial || ' ',
    loteNivel: dataApi?.lote?.nivel || ' ',
    loteSituacaoLote: dataApi?.lote?.situacaoLote || ' ',
    loteProfundidade: dataApi?.lote?.profundidade || null,
    loteConservacao: dataApi?.lote?.conservacao || ' ',
    loteObservacao: ' ',
    loteVago: dataApi?.lote?.vago || "s",
    //unidade
    //obrigatorio
    unidadeId: dataApi?.unidade?.id || ' ',
    unidadeAreaEdicula: dataApi?.unidade?.areaEdicula || 0,
    unidadeAreaConstrucao: dataApi?.unidade?.areaConstrucao || 0,
    //opcional
    unidadeTipo: dataApi?.unidade?.tipo || ' ',
    unidadeConservacao: dataApi?.unidade?.conservacao || ' ',
    unidadePosicao: dataApi?.unidade?.posicao || ' ',
    unidadeEstrutura: dataApi?.unidade?.estrutura || ' ',
    unidadeCobertura: dataApi?.unidade?.cobertura || ' ',
    unidadeInstSanitaria: dataApi?.unidade?.instSanitaria || ' ',
    unidadeInstEletrica: dataApi?.unidade?.instEletrica || ' ',
    unidadeCategoria: dataApi?.unidade?.categoria || ' ',
    unidadePiso: dataApi?.unidade?.piso || ' ',
    unidadeRevestimento: dataApi?.unidade?.revestimento || ' ',
    unidadeFachada: dataApi?.unidade?.fachada || ' ',
    unidadeSituacao: dataApi?.unidade?.situacao || ' ',
    unidadeForro: dataApi?.unidade?.forro || ' ',
    unidadeParede: dataApi?.unidade?.parede || ' ',
    unidadeEsquadrias: dataApi?.unidade?.esquadrias || ' ',
    unidadeAcesso: dataApi?.unidade?.acesso || ' ',
    unidadeClassificacao: dataApi?.unidade?.classificacao || ' ',
    unidadeAlinhamento: dataApi?.unidade?.alinhamento || ' ',
    unidadeUtilizacao: dataApi?.unidade?.utilizacao || ' ',
    unidadeObservacao: ' ',

    //area coberta
    areaCoberta: dataApi?.areaCoberta,

    //obrigatorio
    enderecoId: dataApi?.endereco?.id || null,
    logradouroId: dataApi?.endereco?.logradouro?.id || null,
    testada1: dataApi?.testada?.extensao || null,
    bairro: dataApi?.endereco?.bairro || "não encontrado",
    numero: dataApi?.endereco?.numero || 0,
    complemento: dataApi?.endereco?.complemento || ' ',

    pontoInteresse: (dataApi?.pontos || []).map((ponto) => ({
      ...ponto,
      view: "current",
      idForUpdate: Date.now().toString() + "-" + ponto.nome,
    })),
  });

  const formaterSaveImovel = async () => {
    const obj = {
      enderecoId: Number(inputs.enderecoId),
      logradouroId: Number(inputs.logradouroId),
      testada1: Number(inputs.testada1),
      bairro: inputs.bairro,
      complemento: inputs.complemento,
      numero: Number(inputs.numero),
      lote: {
        id: Number(inputs.loteId),
        areaTerreno: Number(inputs.loteAreaTerreno),
        areaTerrenoIsento: Number(inputs.loteAreaTerrenoIsento),
        areaConstrucao: Number(inputs.loteAreaConstrucao),
        fracaoIdealInfo: Number(inputs.loteFracaoIdeal),
        topografia: inputs.loteTopografia,
        pedologia: inputs.lotePedologia,
        nivelacao: inputs.loteNivelacao,
        tipo: inputs.loteTipo,
        segmento: inputs.loteSegmento,
        lado: inputs.loteLado,
        ocupacao: inputs.loteOcupacao,
        utilizacao: inputs.loteUtilizacao,
        patrimonio: inputs.lotePatrimonio,
        finalidade: inputs.loteFinalidade,
        muro: inputs.loteMuro,
        passeio: inputs.lotePasseio,
        frentes: inputs.loteFrentes,
        arvore: inputs.loteArvore,
        formato: inputs.loteFormato,
        predial: inputs.lotePredial,
        nivel: inputs.loteNivel,
        situacaoLote: inputs.loteSituacaoLote,
        profundidade: Number(inputs.loteProfundidade),
        vago: inputs.loteVago,
        conservacao: inputs.loteConservacao,
      },
      unidade: {
        id: Number(inputs.unidadeId),
        areaEdicula: Number(inputs.unidadeAreaEdicula),
        areaConstrucao: Number(inputs.unidadeAreaConstrucao),
        tipo: inputs.unidadeTipo,
        conservacao: inputs.unidadeConservacao,
        posicao: inputs.unidadePosicao,
        estrutura: inputs.unidadeEstrutura,
        cobertura: inputs.unidadeCobertura,
        instSanitaria: inputs.unidadeInstSanitaria,
        instEletrica: inputs.unidadeInstEletrica,
        categoria: inputs.unidadeCategoria,
        piso: inputs.unidadePiso,
        revestimento: inputs.unidadeRevestimento,
        fachada: inputs.unidadeFachada,
        situacao: inputs.unidadeSituacao,
        forro: inputs.unidadeForro,
        parede: inputs.unidadeParede,
        esquadrias: inputs.unidadeEsquadrias,
        acesso: inputs.unidadeAcesso,
        classificacao: inputs.unidadeClassificacao,
        alinhamento: inputs.unidadeAlinhamento,
        utilizacao: inputs.unidadeUtilizacao,
      },
    };
    try {
      await Api.imoveisSavePost(obj);
    } catch {
      console.log("erro no salvamento");
    }
    return obj;
  };

  const [updateCurretPois, setUpdateCurretPois] = useState(null);

  const gerenciarPontoInteresse = (action, pontoInteresse) => {
    let novosPontosInteresse;
    switch (action) {
      case "remove":
        novosPontosInteresse = inputs.pontoInteresse.map((item) =>
          item.nome === pontoInteresse.nome
            ? {
              ...item,
              view:
                item.view === "current" || item.view === "update"
                  ? "delete"
                  : "removeAdd",
              unidadeId: inputs.unidadeId,
            }
            : item
        );
        break;

      case "add":
        novosPontosInteresse = [
          ...inputs.pontoInteresse,
          {
            ...pontoInteresse,
            idForUpdate: Date.now().toString(),
            view: "add",
            unidadeId: inputs.unidadeId,
          },
        ];
        break;

      case "update":
        novosPontosInteresse = inputs.pontoInteresse.map((item) => {
          if (item.idForUpdate === pontoInteresse.idForUpdate) {
            return {
              ...pontoInteresse,
              view:
                item.view === "current" || item.view === "update"
                  ? "update"
                  : "add",
              unidadeId: inputs.unidadeId,
            };
          }
          return item;
        });
        break;
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      pontoInteresse: novosPontosInteresse,
    }));
  };

  const removerPontoInteresse = (ponto) =>
    gerenciarPontoInteresse("remove", ponto);
  const adicionarPontoInteresse = (ponto) =>
    gerenciarPontoInteresse("add", ponto);
  const updatePontoInteresse = (pontoAtualizado) =>
    gerenciarPontoInteresse("update", pontoAtualizado);

  const requestPois = async () => {
    try {
      for (const item of inputs.pontoInteresse) {
        switch (item.view) {
          case "delete":
            await Api.deletePontoInteresse(item.id);
            break;
          case "update":
            await Api.updatePontoInteresse(item);
            break;
          case "add":
            await Api.createPontoInteresse(item);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error("Erro nas requisições dos pontos de interesse:", error);
    }
  };

  const updateEdificacao = (edificacaoAtualizada) => {
    const edificacoesAtualizadas = coberturaCurrent.current.map((edificacao) =>
      edificacao.id === edificacaoAtualizada.id
        ? edificacaoAtualizada
        : edificacao
    );
    if (
      !edificacoesAtualizadas.some(
        (edificacao) => edificacao.id === edificacaoAtualizada.id
      )
    ) {
      edificacoesAtualizadas.push(edificacaoAtualizada);
    }
    coberturaCurrent.current = edificacoesAtualizadas;

    setInputs((prevInputs) => ({
      ...prevInputs,
      areaCoberta: prevInputs.areaCoberta.map((area) =>
        area.id === edificacaoAtualizada.id
          ? { ...edificacaoAtualizada, view: "update" }
          : area
      ),
    }));
  };

  const requestEdificação = async () => {
    try {
      const areasCobertasParaAtualizar = inputs.areaCoberta.filter(
        (area) => area.view === "update"
      );
      for (const area of areasCobertasParaAtualizar) {
        await Api.updateCobertura(area);
      }
    } catch (error) {
      alert("Erro ao atualizar a cobertura:", error);
    }
  };

  const InputChange = (event) => {
    const name = event.target.name;
    const valor = event.target.value;
    setInputs((valores) => ({ ...valores, [name]: valor }));
  };

  const requestProprietario = async () => {
    const dadosProprietarios = {
      name: inputs.proprietarioNome || null,
      cpfCnpj: inputs.proprietarioCpfCnpj || null,
    };

    try {
      const resposta = await Api.searchProprietario(dadosProprietarios);
      const proprietarioId = Number(resposta?.data?.proprietarios[0]?.id);
      const toggleProprietario = {
        proprietarioId,
        imovelId: Number(inputs?.unidadeId) || null,
      };
      if (toggleProprietario.proprietarioId) {
        await Api.updateProprietarioImovel(toggleProprietario);
      }
    } catch (erro) {
      console.error("Erro ao buscar proprietário:", erro);
    }
  };

  const submitForm = async (event) => {
    setSearching(true);
    try {
      await formaterSaveImovel();
      await requestEdificação();
      await requestPois();
      await requestProprietario();
      alert("Alterações salvas com sucesso.");
    } catch (error) {
      alert(`Erro ao salvar alterações: ${error}`);
    } finally {
      setSearching(false);
    }
  };

  const mapManger = useRef(null);
  const vectorManeger = useRef(null);
  const coberturaCurrent = useRef([]); // Inicializando como um array vazio
  const [onClickCobertura, setOnClickCobertura] = useState(null);

  useEffect(() => {
    if (!vectorManeger.current) {
      // setTimeout(() => {
      const coordendaCentral = configurarCoordenadaCentral(
        dataApi?.areaCobertaCentro[0]?.geom?.coordinates
      );

      vectorManeger.current = new ManegerVectorLayer([505651.75, 7570208]);
      vectorManeger.current.setMapElement(mapManger.current);
      const coberturas = inputs?.areaCoberta.map((area) => ({
        coordenadas: [area?.geom.coordinates],
        id: {
          id: area?.id,
          andares: area?.andares,
          beiral: area?.beiral,
          cursorPointer: true,
        },
      }));



      const lote = [{ coordenadas: dataApi?.lote.geom.coordinates, id: {} }];
      vectorManeger.current.addCursorPointer();
      vectorManeger.current.setZoomElement(20);
      vectorManeger.current.iniciarVectorLayer("Lote", lote);
      vectorManeger.current.iniciarVectorLayer(
        "Cobertura",
        coberturas,
        (item) => {
          let dadosCobertura = item.ID;
          if (coberturaCurrent.current) {
            dadosCobertura =
              coberturaCurrent.current.find(
                (cobertura) => cobertura?.id === item?.ID?.id
              ) || item.ID;
          }

          setOnClickCobertura({ cor: item.COLOR, dados: dadosCobertura });
          setModalEdificacaoAberto(true);
        }
      );
      // }, 0);
      vectorManeger.current.initBoudingBox('Lote')
    }
  }, [])

  return (
    <>
      <div className='container-imovel'>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab label='Geral' {...a11yProps(0)} />
              <Tab label='Edificação' {...a11yProps(1)} />
              <Tab label='Terreno' {...a11yProps(2)} />
              <Tab label='Proprietário' {...a11yProps(3)} />
              {/* <Tab label='Panorama' {...a11yProps(4)} />
              <Tab label='Arquivos' {...a11yProps(5)} /> */}
            </Tabs>
          </Box>
          <form style={{ width: "100%", height: "100%" }}>
            {/* GERAL */}
            <TabPanel value={value} index={0}>
              <div className='conteudo-lista'>
                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel desabilitado'
                    label='N° de inscrição'
                    variant='outlined'
                    size='small'
                    disabled
                    value={dataApi?.inscricaoImovel}
                    sx={{ margin: "5px" }}
                  />
                  <TextField
                    className='input-imovel desabilitado'
                    label='N° de inscrição reduzido'
                    variant='outlined'
                    size='small'
                    disabled
                    value={inputs?.enderecoId}
                    sx={{ margin: "5px" }}
                  />
                </div>
                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel desabilitado'
                    label='Logradouro'
                    variant='outlined'
                    size='small'
                    disabled
                    value={dataApi?.endereco?.logradouro?.nome}
                    sx={{ margin: "5px" }}
                  />
                </div>
                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel'
                    label='Número'
                    variant='outlined'
                    size='small'
                    value={inputs?.numero}
                    sx={{ margin: "5px" }}
                    name='numero'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                  <TextField
                    className='input-imovel'
                    label='Bairro'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.bairro}
                    name='bairro'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                  <TextField
                    className='input-imovel'
                    label='Complemento'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.complemento}
                    name='complemento'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                </div>
                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel'
                    label='Área edicula'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.unidadeAreaEdicula}
                    name='unidadeAreaEdicula'
                    onChange={InputChange}
                    type='text'
                  />
                  <TextField
                    className='input-imovel'
                    label='Unidade área construção'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.unidadeAreaConstrucao}
                    name='unidadeAreaConstrucao'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                </div>
                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel'
                    label='Área terreno'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.loteAreaTerreno}
                    name='loteAreaTerreno'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                  <TextField
                    className='input-imovel'
                    label='Área terreno isento'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.loteAreaTerrenoIsento}
                    name='loteAreaTerrenoIsento'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                  <TextField
                    className='input-imovel'
                    label='Área construção'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.loteAreaConstrucao}
                    name='loteAreaConstrucao'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                </div>
                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel'
                    label='Fração ideal do lote'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.loteFracaoIdeal}
                    name='loteFracaoIdeal'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                  <TextField
                    className='input-imovel'
                    label='Testada principal'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs?.testada1}
                    name='testada1'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                </div>
                <div className='conteudo-imovel'>
                  <div className='linha' />
                </div>

                <div className='conteudo-imovel' style={{ margin: "5px" }}>
                  <p className='input-imovel'>Nome</p>
                  <p className='input-imovel'>Ponto de Interesse</p>
                  <Button
                    style={{ width: "50%" }}
                    variant='outlined'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setUpdateCurretPois(null);
                      setModalAberto(true);
                    }}
                  >
                    Ponto de Interesse
                  </Button>
                </div>
                <div className='linha-gray' />
                <div className='list-ponto-interesse'>
                  {inputs?.pontoInteresse
                    ?.filter(
                      (ponto) =>
                        ponto.view !== "delete" && ponto.view !== "removeAdd"
                    )
                    .map((ponto, index) => (
                      <>
                        <div
                          key={index}
                          className='conteudo-imovel'
                          style={{ width: "100%", margin: 10 }}
                        >
                          <a
                            className='input-imovel'
                            style={{ color: "#1976D2" }}
                          >
                            {ponto.nome}
                          </a>
                          <a
                            className='input-imovel'
                            style={{ color: "#1976D2" }}
                          >
                            {ponto.tipo}
                          </a>
                          <div style={{ display: "flex" }}>
                            <Button
                              style={{
                                marginLeft: "15%",
                                color: "gray",
                                width: "2rem",
                                height: "2rem",
                                borderRadius: "50%",
                                minWidth: "unset",
                                padding: 0,
                              }}
                              variant='text'
                              onClick={() => {
                                setModalAberto(true);
                                setUpdateCurretPois(ponto);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPen}
                                style={{ fontSize: "0.8rem" }}
                              />
                            </Button>
                            <Button
                              style={{
                                marginLeft: "5%",
                                color: "red",
                                width: "2rem",
                                height: "2rem",
                                borderRadius: "50%",
                                minWidth: "unset",
                                padding: 0,
                              }}
                              variant='text'
                              onClick={() => removerPontoInteresse(ponto)}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{ fontSize: "0.8rem" }}
                              />
                            </Button>
                          </div>
                        </div>
                        <div className='linha-gray' />
                      </>
                    ))}
                </div>
              </div>
            </TabPanel>
            {/* EDIFICACAO */}
            <div style={{ display: value === 1 ? "block" : "none" }}>
              <div className='conteudo-lista'>
                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      label='Tipo'
                      value={inputs.unidadeTipo}
                      name='unidadeTipo'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Tipo...</MenuItem>
                      <MenuItem value='Apartamento'>Apartamento</MenuItem>
                      <MenuItem value='Casa/Sobrado'>Casa/Sobrado</MenuItem>
                      {/* <MenuItem value="Comercial" >Comercial</MenuItem> */}
                      <MenuItem value='Fábrica/Indústria'>
                        Fábrica/Indústria
                      </MenuItem>
                      <MenuItem value='Galpão'>Galpão</MenuItem>
                      {/* <MenuItem value="residencial/comercial" >Residencial/Comercial</MenuItem> */}
                      <MenuItem value='Sala/Loja'>Sala/Loja</MenuItem>
                      <MenuItem value='Telheiros'>Telheiros</MenuItem>
                      <MenuItem value='Misto'>Misto</MenuItem>
                      <MenuItem value='Outros'>Outros</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Posição</InputLabel>
                    <Select
                      label='Posição'
                      value={inputs.unidadePosicao}
                      name='unidadePosicao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Posição...</MenuItem>
                      <MenuItem value='ISOLADA'>Isolada</MenuItem>
                      <MenuItem value='GEMINADA'>Germinada</MenuItem>
                      <MenuItem value='CONJUGADA'>Conjugada</MenuItem>
                      <MenuItem value='COND. VERTICAL'>Cond. Vertical</MenuItem>
                      <MenuItem value='COND. HORIZONTAL'>
                        Cond. Horizontal
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Estrutura</InputLabel>
                    <Select
                      label='Estrutura'
                      value={inputs.unidadeEstrutura}
                      name='unidadeEstrutura'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Estrutura...</MenuItem>
                      <MenuItem value='ALVENARIA'>Alvenaria</MenuItem>
                      <MenuItem value='CONCRETO'>Concreto</MenuItem>
                      <MenuItem value='MADEIRA'>Madeira</MenuItem>
                      <MenuItem value='METALICA'>Metalica</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Cobertura</InputLabel>
                    <Select
                      label='Cobertura'
                      value={inputs.unidadeCobertura}
                      name='unidadeCobertura'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Cobertura...</MenuItem>
                      <MenuItem value='ESPECIAL'>Especial</MenuItem>
                      <MenuItem value='LAJE'>Laje</MenuItem>
                      <MenuItem value='TELHA BARRO'>Telha Barro</MenuItem>
                      <MenuItem value='TELHA FIBRA'>
                        Telha Fibrocimento
                      </MenuItem>
                      <MenuItem value='ZINCO PALHA'>Zinco Palha</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Conservação</InputLabel>
                    <Select
                      label='Conservação'
                      value={inputs.unidadeConservacao}
                      name='unidadeConservacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Conservação...</MenuItem>
                      <MenuItem value='Boa'>Boa</MenuItem>
                      <MenuItem value='Ma'>Má</MenuItem>
                      <MenuItem value='Otimo/novo'>Ótimo/Novo</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Instalação Sanitária</InputLabel>
                    <Select
                      label='InstalacaoSanitaria'
                      value={inputs.unidadeInstSanitaria}
                      name='unidadeInstSanitaria'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Instalação Sanitária...</MenuItem>
                      <MenuItem value='+1 INTERNA'>
                        Mais de uma interna
                      </MenuItem>
                      <MenuItem value='EXTERNA'>Externa</MenuItem>
                      <MenuItem value='INEXISTENTE'>Inexistente</MenuItem>
                      <MenuItem value='INT.COMPL.'>Int. Completa</MenuItem>
                      <MenuItem value='INT.SIMPL.'>Int. Simples</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Instalação Elétrica</InputLabel>
                    <Select
                      label='InstalacaoEletrica'
                      value={inputs.unidadeInstEletrica}
                      name='unidadeInstEletrica'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Instalação Elétrica...</MenuItem>
                      <MenuItem value='APARENTE'>Aparente</MenuItem>
                      <MenuItem value='EMBUTIDA'>Embutida</MenuItem>
                      <MenuItem value='INEXISTE'>Inexistente</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Categoria</InputLabel>
                    <Select
                      label='Categoria'
                      value={inputs.unidadeCategoria}
                      name='unidadeCategoria'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Categoria...</MenuItem>
                      <MenuItem value='APARTAMENTO'>Apartamento</MenuItem>
                      <MenuItem value='CASA'>Casa</MenuItem>
                      <MenuItem value='GALPAO'>Galpão</MenuItem>
                      <MenuItem value='INDUSTRIA'>Indústria</MenuItem>
                      <MenuItem value='LOJA'>Loja</MenuItem>
                      <MenuItem value='TELHEIRO'>Telheiro</MenuItem>
                      <MenuItem value='ESPECIAL'>Especial</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Piso</InputLabel>
                    <Select
                      label='Piso'
                      value={inputs.unidadePiso || ""}
                      name='unidadePiso'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value='CERAMICA'>Cerâmica</MenuItem>
                      <MenuItem value='CIMENTO'>Cimento</MenuItem>
                      <MenuItem value='TERRA'>Terra</MenuItem>
                      <MenuItem value='PEDRA/GRANITO'>Pedra/Granito</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Revestimento</InputLabel>
                    <Select
                      label='Revestimento'
                      value={inputs.unidadeRevestimento}
                      name='unidadeRevestimento'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Revestimento...</MenuItem>
                      <MenuItem value='ALVENARIA'>Alvenaria</MenuItem>
                      <MenuItem value='CAIACAO'>Caição</MenuItem>
                      <MenuItem value='CERAMICA'>Cerâmica</MenuItem>
                      <MenuItem value='ESPECIAL'>Especial</MenuItem>
                      <MenuItem value='MADEIRA'>Madeira</MenuItem>
                      <MenuItem value='OLEO'>Óleo</MenuItem>
                      <MenuItem value='REBOCO'>Reboco</MenuItem>
                      <MenuItem value='SEM'>Sem</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Fachada</InputLabel>
                    <Select
                      label='Fachada'
                      value={inputs.unidadeFachada}
                      name='unidadeFachada'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Fachada...</MenuItem>
                      <MenuItem value='ALINHADA'>Alinhada</MenuItem>
                      <MenuItem value='RECUADA'>Recuada</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Situação</InputLabel>
                    <Select
                      label='Situacao'
                      value={inputs.unidadeSituacao}
                      name='unidadeSituacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Situação...</MenuItem>
                      <MenuItem value='CONJUGADA'>Conjugada</MenuItem>
                      <MenuItem value='GEMINADA'>Geminada</MenuItem>
                      <MenuItem value='ISOLADA'>Isolada</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Forro</InputLabel>
                    <Select
                      label='Forro'
                      value={inputs.unidadeForro}
                      name='unidadeForro'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Forro...</MenuItem>
                      <MenuItem value='LAJE'>Laje</MenuItem>
                      <MenuItem value='CHAPAS'>Chapas</MenuItem>
                      <MenuItem value='ESPECIAL'>Especial</MenuItem>
                      <MenuItem value='ESTUQUE'>Estuque</MenuItem>
                      <MenuItem value='INEXISTE'>Inexistente</MenuItem>
                      <MenuItem value='MADEIRA'>Madeira</MenuItem>
                      <MenuItem value='SEM'>Sem</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Paredes</InputLabel>
                    <Select
                      label='Paredes'
                      value={inputs.unidadeParede}
                      name='unidadeParede'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Paredes...</MenuItem>
                      <MenuItem value='ALVENARIA'>Alvenaria</MenuItem>
                      <MenuItem value='CHOCA_BARRACO'>Choça/Barraco</MenuItem>
                      <MenuItem value='MADEIRA'>Madeira</MenuItem>
                      <MenuItem value='TAIPA'>Taipa</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Esquadrias</InputLabel>
                    <Select
                      label='Esquadrias'
                      value={inputs.unidadeEsquadrias}
                      name='unidadeEsquadrias'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Esquadrias...</MenuItem>
                      <MenuItem value='MADEIRA'>Madeira</MenuItem>
                      <MenuItem value='FERRO'>Ferro</MenuItem>
                      <MenuItem value='ALUMINIO'>Alumínio</MenuItem>
                      <MenuItem value='ESPECIAL'>Especial</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Acesso</InputLabel>
                    <Select
                      label='Acesso'
                      value={inputs.unidadeAcesso}
                      name='unidadeAcesso'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Acesso...</MenuItem>
                      <MenuItem value='PERMITIDO'>Permitido</MenuItem>
                      <MenuItem value='NAO_PERMITIDO'>Não Permitido</MenuItem>
                      <MenuItem value='IMOVEL_VAZIO'>Imóvel Vazio</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Classificação</InputLabel>
                    <Select
                      label='Classificação'
                      value={inputs.unidadeClassificacao}
                      name='unidadeClassificacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Classificação...</MenuItem>
                      <MenuItem value='POPULAR'>Popular</MenuItem>
                      <MenuItem value='BAIXO'>Baixo</MenuItem>
                      <MenuItem value='NORMAL'>Normal</MenuItem>
                      <MenuItem value='ALTO'>Alto</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Alinhamento</InputLabel>
                    <Select
                      label='Alinhamento'
                      value={inputs.unidadeAlinhamento}
                      name='unidadeAlinhamento'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Alinhamento...</MenuItem>
                      <MenuItem value='FRENTE_ALINHADA'>
                        Frente Alinhada
                      </MenuItem>
                      <MenuItem value='FRENTE_RECUADA'>Frente Recuada</MenuItem>
                      <MenuItem value='FRENTE_AVANCADA'>
                        Frente Avançada
                      </MenuItem>
                      <MenuItem value='FUNDOS'>Fundos</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Utilização</InputLabel>
                    <Select
                      label='Utilização'
                      value={inputs.unidadeUtilizacao}
                      name='unidadeUtilizacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Utilização...</MenuItem>
                      <MenuItem value='RESIDENCIAL'>Residencial</MenuItem>
                      <MenuItem value='COMERCIAL'>Comercial</MenuItem>
                      <MenuItem value='SERVICOS'>Serviços</MenuItem>
                      <MenuItem value='INDUSTRIAL'>Industrial</MenuItem>
                      <MenuItem value='RELIGIOSO'>Religioso</MenuItem>
                      <MenuItem value='PUBLICA'>Pública</MenuItem>
                      <MenuItem value='MISTA'>Mista</MenuItem>
                      <MenuItem value='AGROPECUARIA'>Agropecuária</MenuItem>
                      <MenuItem value='TERRENO_SEM_USO'>
                        Terreno sem Uso
                      </MenuItem>
                      <MenuItem value='OUTROS'>Outros</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    className='input-imovel'
                    label='Observação'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs.unidadeObservacao}
                    name='unidadeObservacao'
                    onChange={(e) =>
                      InputChange({
                        target: {
                          name: "unidadeObservacao",
                          value: e.target.value,
                        },
                      })
                    }
                    autoComplete='off'
                  />
                </div>

                <div className='conteudo-imovel'>
                  <div className='linha'></div>
                </div>
                <div
                  className='conteudo-imovel'
                  style={{ justifyContent: "start" }}
                >
                  <p>
                    Selecione uma área coberta do imóvel, para visualizar e
                    alterar suas informações sobre o número de pavimentos e
                    beiral
                  </p>
                </div>
                <div
                  ref={mapManger}
                  style={{ display: "flex", width: "100%", height: "500px" }}
                />
              </div>
            </div>
            {/* TERRENO */}
            <TabPanel value={value} index={2}>
              <div className='conteudo-lista'>
                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Topografia</InputLabel>
                    <Select
                      label='Topografia'
                      value={inputs.loteTopografia}
                      name='loteTopografia'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Topografia...</MenuItem>
                      <MenuItem value='Aclive/Declive'>Aclive/Declive</MenuItem>
                      <MenuItem value='Dificil Aproveitamento'>
                        Difícil aproveitamento
                      </MenuItem>
                      <MenuItem value='Regular'>Regular</MenuItem>
                      <MenuItem value='Irregular'>Irregular</MenuItem>
                      <MenuItem value='Plano'>Plano</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Pedologia</InputLabel>
                    <Select
                      label='Pedologia'
                      value={inputs.lotePedologia}
                      name='lotePedologia'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Pedologia...</MenuItem>
                      <MenuItem value='FIRME'>Firme</MenuItem>
                      <MenuItem value='INUNDAVEL'>Inundável</MenuItem>
                      <MenuItem value='MISTO'>Misto</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Ocupação</InputLabel>
                    <Select
                      label='Ocupação'
                      value={inputs.loteOcupacao}
                      name='loteOcupacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Ocupação...</MenuItem>
                      <MenuItem value='Edificado'>Edificado</MenuItem>
                      <MenuItem value='Vago'>Vago</MenuItem>
                      <MenuItem value='Construção em Andamento'>
                        Construção em Andamento
                      </MenuItem>
                      <MenuItem value='Construção Paralisada'>
                        Construção Paralisada
                      </MenuItem>
                      <MenuItem value='Ruínas'>Ruínas</MenuItem>
                      <MenuItem value='Em Reforma'>Em Reforma</MenuItem>
                      <MenuItem value='Em Demolição'>Em Demolição</MenuItem>
                      <MenuItem value='Praça'>Praça</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Utilização</InputLabel>
                    <Select
                      label='Utilização'
                      value={inputs.loteUtilizacao}
                      name='loteUtilizacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Utilização...</MenuItem>
                      <MenuItem value='RESIDENCIAL'>Residencial</MenuItem>
                      <MenuItem value='COMERCIAL'>Comercial</MenuItem>
                      <MenuItem value='SERVICOS'>Serviços</MenuItem>
                      <MenuItem value='INDUSTRIAL'>Industrial</MenuItem>
                      <MenuItem value='RELIGIOSO'>Religioso</MenuItem>
                      <MenuItem value='PUBLICA'>Pública</MenuItem>
                      <MenuItem value='MISTA'>Mista</MenuItem>
                      <MenuItem value='AGROPECUARIA'>Agropecuária</MenuItem>
                      <MenuItem value='TERRENO_SEM_USO'>
                        Terreno sem Uso
                      </MenuItem>
                      <MenuItem value='OUTROS'>Outros</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Patrimônio</InputLabel>
                    <Select
                      label='Patrimônio'
                      value={inputs.lotePatrimonio}
                      name='lotePatrimonio'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Patrimônio...</MenuItem>
                      <MenuItem value='Estadual'>Estadual</MenuItem>
                      <MenuItem value='Municipal'>Municipal</MenuItem>
                      <MenuItem value='Particular'>Particular</MenuItem>
                      <MenuItem value='Público'>Público</MenuItem>
                      <MenuItem value='Religioso'>Religioso</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Finalidade</InputLabel>
                    <Select
                      label='Finalidade'
                      value={inputs.loteFinalidade}
                      name='loteFinalidade'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Finalidade...</MenuItem>
                      <MenuItem value='Uso próprio'>Uso próprio</MenuItem>
                      <MenuItem value='Locatícia'>Locatícia</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Muro</InputLabel>
                    <Select
                      label='Muro'
                      value={inputs.loteMuro}
                      name='loteMuro'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Muro...</MenuItem>
                      <MenuItem value='Sim'>Sim</MenuItem>
                      <MenuItem value='Não'>Não</MenuItem>
                      <MenuItem value='Sem info.'>Sem info.</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Passeio</InputLabel>
                    <Select
                      label='Passeio'
                      value={inputs.lotePasseio}
                      name='lotePasseio'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Passeio...</MenuItem>
                      <MenuItem value='Sim'>Sim</MenuItem>
                      <MenuItem value='Não'>Não</MenuItem>
                      <MenuItem value='Sem info.'>Sem info.</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Frentes</InputLabel>
                    <Select
                      label='Frentes'
                      value={inputs.loteFrentes}
                      name='loteFrentes'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Frentes...</MenuItem>
                      <MenuItem value='Encravado'>Encravado</MenuItem>
                      <MenuItem value='Gleba'>Gleba</MenuItem>
                      <MenuItem value='Mais de Uma Frente'>
                        Mais de Uma Frente
                      </MenuItem>
                      <MenuItem value='Uma Frente'>Uma Frente</MenuItem>
                      <MenuItem value='Vila'>Vila</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Formato</InputLabel>
                    <Select
                      label='Formato'
                      value={inputs.loteFormato}
                      name='loteFormato'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Formato...</MenuItem>
                      <MenuItem value='IRREGULAR'>Irregular</MenuItem>
                      <MenuItem value='NAO DEFINIDO'>Não Definido</MenuItem>
                      <MenuItem value='REGULAR'>Regular</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Árvores</InputLabel>
                    <Select
                      label='Árvores'
                      value={inputs.loteArvore}
                      name='loteArvore'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Árvores...</MenuItem>
                      <MenuItem value='N'>Não</MenuItem>
                      <MenuItem value='S'>Sim</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    className='input-imovel'
                    label='Nivelação'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs.loteNivelacao}
                    name='loteNivelacao'
                    onChange={InputChange}
                    autoComplete='off'
                    type='text'
                  />
                </div>

                <div className='conteudo-imovel'>
                  <TextField
                    className='input-imovel'
                    label='Tipo'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs.loteTipo}
                    name='loteTipo'
                    onChange={InputChange}
                    autoComplete='off'
                    type='text'
                  />
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Conservação</InputLabel>
                    <Select
                      label='Conservação'
                      value={inputs.loteConservacao}
                      name='loteConservacao'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Conservação...</MenuItem>
                      <MenuItem value='Boa'>Boa</MenuItem>
                      <MenuItem value='Ma'>Má</MenuItem>
                      <MenuItem value='Otimo/Novo'>Ótimo/Novo</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    className='input-imovel'
                    label='Segmento'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs.loteSegmento}
                    name='loteSegmento'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Lado</InputLabel>
                    <Select
                      label='Lado'
                      value={inputs.loteLado}
                      name='loteLado'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Lado...</MenuItem>
                      <MenuItem value='D'>Direito</MenuItem>
                      <MenuItem value='E'>Esquerdo</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='conteudo-imovel'>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Predial</InputLabel>
                    <Select
                      label='Predial'
                      value={inputs.lotePredial}
                      name='lotePredial'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Predial...</MenuItem>
                      <MenuItem value='Sim'>Sim</MenuItem>
                      <MenuItem value='Não'>Não</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Nível</InputLabel>
                    <Select
                      label='Nível'
                      value={inputs.loteNivel}
                      name='loteNivel'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Nível...</MenuItem>
                      <MenuItem value='Ao Nível'>Ao Nível</MenuItem>
                      <MenuItem value='Abaixo'>Abaixo</MenuItem>
                      <MenuItem value='Acima'>Acima</MenuItem>
                      <MenuItem value='Irregular'>Irregular</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: "100%", margin: "5px" }}
                    size='small'
                  >
                    <InputLabel>Situação lote</InputLabel>
                    <Select
                      label='Situação Lote'
                      value={inputs.loteSituacaoLote}
                      name='loteSituacaoLote'
                      onChange={InputChange}
                      autoComplete='off'
                    >
                      <MenuItem value={null}>Situação lote...</MenuItem>
                      <MenuItem value='Esquina/+ de 1 frente'>
                        Esquina/+ de 1 frente
                      </MenuItem>
                      <MenuItem value='frente'>frente</MenuItem>
                      <MenuItem value='Meio de Quadra'>Meio de Quadra</MenuItem>
                      <MenuItem value='Encravado'>Encravado</MenuItem>
                      <MenuItem value='Gleba'>Gleba</MenuItem>
                      <MenuItem value='Vila/Condomínio'>
                        Vila/Condomínio
                      </MenuItem>
                      <MenuItem value='Sítio Rec.'>Sítio Rec.</MenuItem>
                      <MenuItem value='Esquina/+ de 1 frente'>
                        Esquina/+ de 1 frente
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    className='input-imovel'
                    label='Profundidade'
                    variant='outlined'
                    size='small'
                    sx={{ margin: "5px" }}
                    value={inputs.loteProfundidade}
                    name='loteProfundidade'
                    onChange={InputChange}
                    autoComplete='off'
                  />
                </div>

                {/* <div className="conteudo-imovel">
                                    <TextField
                                        className="input-imovel"
                                        label="Observação"
                                        variant="outlined"
                                        size="small"
                                        sx={{ margin: '5px', marginRight: '75.5%' }}
                                        value={inputs.loteObservacao}
                                        name="loteObservacao"
                                        onChange={InputChange}
                                        autoComplete="off"
                                    />
                                </div> */}
              </div>
            </TabPanel>
            {/* PROPRIETARIO */}
            <TabPanel value={value} index={3}>
              <div className='conteudo-lista'>
                <div
                  className='conteudo-imovel'
                  style={{ justifyContent: "start" }}
                >
                  <Button
                    style={{ width: "19%", margin: "5px" }}
                    size='small'
                    variant='contained'
                    startIcon={<EditIcon />}
                    onClick={() => setEditarProprietario(!editarProprietario)}
                  >
                    {editarProprietario ? "Desativar edição" : "Ativar edição"}
                  </Button>
                </div>
                <div className='conteudo-imovel'>
                  <TextField
                    className={`input-imovel ${editarProprietario ? "" : "desabilitado"
                      }`}
                    label='Proprietário'
                    variant='outlined'
                    size='small'
                    disabled={!editarProprietario}
                    sx={{ margin: "5px" }}
                    value={inputs.proprietarioNome}
                    name='proprietarioNome'
                    onChange={InputChange}
                  />
                  <TextField
                    className={`input-imovel ${editarProprietario ? "" : "desabilitado"
                      }`}
                    label='CPF/CNPJ'
                    variant='outlined'
                    size='small'
                    disabled={!editarProprietario}
                    sx={{ margin: "5px" }}
                    value={inputs.proprietarioCpfCnpj}
                    name='proprietarioCpfCnpj'
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      let maskedValue = "";
                      if (value.length <= 11) {
                        // CPF
                        maskedValue = value.replace(
                          /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
                          "$1.$2.$3-$4"
                        );
                      } else {
                        // CNPJ
                        maskedValue = value.replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
                          "$1.$2.$3/$4-$5"
                        );
                      }
                      InputChange({
                        target: {
                          name: "proprietarioCpfCnpj",
                          value: maskedValue,
                        },
                      });
                    }}
                    inputProps={{
                      maxLength: 18,
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            {/* ARQUIVO */}
            <TabPanel value={value} index={4}>
              <PanoramaImoveis id={inputs.loteId} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <div
                className='conteudo-lista'
                style={{ height: "50px", padding: 0 }}
              ></div>
            </TabPanel>
          </form>
        </Box>
      </div>

      <div className='barra-direita'>
        <Fab
          sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
          color='primary'
          onClick={onBack}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Fab>
        <Fab
          sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
          color='primary'
          onClick={submitForm}
        >
          {searching ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faFloppyDisk} />
          )}
        </Fab>
      </div>
      {modalAberto && (
        <section style={{ position: "relative" }}>
          <ModalPontoInteresse
            atualizar={(item) =>
              item.idForUpdate
                ? updatePontoInteresse(item)
                : adicionarPontoInteresse(item)
            }
            onClose={() => setModalAberto(false)}
            pontoInteresse={updateCurretPois || "não"}
          />
        </section>
      )}
      {modalEdificacaoAberto && (
        <section style={{ position: "relative" }}>
          <ModalEdificacao
            onClose={() => setModalEdificacaoAberto(false)}
            inputs={onClickCobertura}
            atualizar={(item) => updateEdificacao(item)}
          />
        </section>
      )}
    </>
  );
}
