import React from "react";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";

import { itajubaMapInfos } from "../utils/itajubaMapInfos";

// Função para pegar o nome dos logradouros e a linha dos mesmos
const getVectorLayerByStreets = () => {
  // Estilo do texto e da linha incial
  const style = new Style({
    text: new Text({}),
    stroke: new Stroke({ color: "rgba(24, 24, 46, 0.949)", width: 2 }),
  });

  const defaultText = style.getText(); // Pega o objeto de texto padrão

  return new VectorLayer({
    source: new VectorSource({
      format: new GeoJSON(),
      url: itajubaMapInfos.vectorSourceUrl,
    }),
    style: (feature) => {
      const nameText = feature.get("nome") ?? feature.get("name");

      // Estilização do texto
      defaultText.setText(nameText);
      defaultText.setFill(new Fill({ color: "#fff" }));
      defaultText.setRotateWithView(true);
      defaultText.setMaxAngle(0.1);
      defaultText.setScale(1.4);
      defaultText.setPlacement("line");
      defaultText.setStroke(
        new Stroke({ color: "rgba(24, 24, 46, 0.949)", width: 2 })
      );

      return style;
    },
  });
};

const getTileLayerWMSByLayerName = (layerName) => {
  return new TileLayer({
    source: new TileWMS({
      url: itajubaMapInfos.tilesWMS.url,
      cacheSize: 4096,
      params: {
        format: itajubaMapInfos.tilesWMS.params.format,
        VERSION: itajubaMapInfos.tilesWMS.params.VERSION,
        tiled: itajubaMapInfos.tilesWMS.params.tiled,
        STYLES: "",
        LAYERS: `${itajubaMapInfos.tilesWMS.params.LAYERS}${layerName}`,
        tilesOrigin: itajubaMapInfos.tilesWMS.params.tilesOrigin.final,
      },
    }),
  });
};

// Função para adicionar o layer de logradouro
//type: 0 Geral | 1 Coleta |
export const getLogradouroLayer = (vectorManeger, vecOlUidRef, type) => {
  // Aqui vai criar o nome da rua e a linha da mesma
  let layer = getVectorLayerByStreets();
  vecOlUidRef.current["logradouro" + "_nome"] = layer.ol_uid;
  layer.setZIndex(type === 1 ? 2 : 21);
  vectorManeger.current.addLayer(layer);
  // Aqui vai setar o layer de logradouro
  layer = getTileLayerWMSByLayerName("logradouro");
  vecOlUidRef.current["logradouro"] = layer.ol_uid;
  layer.setZIndex(type === 1 ? 1 : 20);
  vectorManeger.current.addLayer(layer);
};
