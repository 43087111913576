import React, { useState } from "react";
import { Modal, Box, Typography, FormControlLabel, Checkbox, Button } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Planta from "./ServisesBic";

export const ModalImprimir = ({ state, onClose, generatePdf, id }) => {
    const [options, setOptions] = useState({
        fotografia: false,
        planta: false,
    });

    if (!state) return null;


    const handleChange = (event) => {
        const { name, checked } = event.target;
        setOptions((prevOptions) => ({ ...prevOptions, [name]: checked }));
    };

    const handlePrint = () => {
        generatePdf(options); // Passa o estado atual das opções para a função de geração de PDF
    };

    return (
        <Modal open={state} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    height: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Button
                    aria-label="Fechar"
                    style={{ padding: 0, minWidth: 0, position: 'absolute', right: 30, top: 20 }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </Button>
                {/* Header */}
                <Typography variant="h6" component="h2">
                    Imprimir BIC
                </Typography>

                {/* Checkboxes */}
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3, height: '100%' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="fotografia"
                                checked={options.fotografia}
                                onChange={handleChange}
                            />
                        }
                        label="Fotografias"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="planta"
                                checked={options.planta}
                                onChange={handleChange}
                            />
                        }
                        label="Planta de localização e situação"
                    />
                </Box>
                {/* <Planta id={'6043'} /> */}
                {/* Footer com Botão */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button variant="contained" color="primary" onClick={handlePrint}>
                        Imprimir
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
