import { createContext, useState, useEffect, useRef } from 'react';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4';
import { Colors } from '../../../../Map/Component/Colors';

const MapContextAdv = createContext();

const MapProviderAdv = ({ children }) => {
  const [testadas, setTestadas] = useState([]);
  const [coberturas, setCoberturas] = useState([]);
  const [radioButtonState, setRadioButtonState] = useState(['Lote', 'Testada', 'LotesVizinhos', 'Cobertura']);
  const [currentZoom, setCurrentZoom] = useState(5);
  const [coordenadasPoligono, setCoordenadasPoligono] = useState([]);
  const [coordenadasLoteVizinho, setCoordenadasLoteVizinho] = useState([[]]);
  const [coordenadasPoligonoTestadas, setCoordenadasPoligonoTestadas] = useState([]);
  const [coordenadasPoligonoCoberturas, setCoordenadasPoligonoCoberturas] = useState([]);
  const [pontoCentralMap, setPontoCentralMap] = useState([]);
  const mapRef = useRef(null);
  const vectorLayerRef = useRef(null);
  const vectorLayerRefTestada = useRef(null);
  const vectorLayerRefCoberturas = useRef(null);
  const vectorLayerRefLoteVizinho = useRef(null);
  const [arrayLoteCorbeturas, setArrayLoteCorbeturas] = useState([])

  const [newMap, setNewMap] = useState(null);
  const initColors = () => {
    const obj = new Colors()
    const arrayTestada = obj.getColors()
    return arrayTestada
  }
  const colorsLotes = initColors().lote
  const colorsTestadas = initColors().testada
  const colorsCoberturas = initColors().cobertura
 
  const colorsCoberuturaStroke = [
    "rgb(174, 199, 232)",
    "rgb(255, 127, 14)",
    "rgb(44, 160, 44)",
    "rgb(152, 223, 138)",
    "rgb(214, 39, 40)",
    "rgb(255, 152, 150)",
    "rgb(148, 103, 189)",
    "rgb(197, 176, 213)",
    "rgb(140, 86, 75)",
    "rgb(196, 156, 148)",
    "rgb(227, 119, 194)",
    "rgb(247, 182, 210)",
    "rgb(127, 127, 127)",
    "rgb(199, 199, 199)",
    "rgb(188, 189, 34)",
    "rgb(219, 219, 141)",
    "rgb(23, 190, 207)",
    "rgb(158, 218, 229)",
  ]

  const extractCoordinates = (array) => {
    return array.flatMap(subArray1 =>
      subArray1
    );
  };
  const extractCoordinates2 = (array) => {
    return array.map(subArray1 =>
      subArray1
    );
  };

  const configurarCoordenadaCentral = (coordenadas) => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const coordenadasLimpo = coordenadas;
    const latLngCoordinates = proj4(utmProjection, latLngProjection, coordenadasLimpo);

    setPontoCentralMap(latLngCoordinates)
  };

  const configurarCoordenada = (coordenadasPoligonoNaoConfiguradas) => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const convertedCoordinates = coordenadasPoligonoNaoConfiguradas.map((polygon) => {
      return polygon.map((coordinate) => {
        return proj4(utmProjection, latLngProjection, coordinate);
      });
    });
    return (convertedCoordinates);
  };

  const createGeoJsonData = (coordinatesArray) => {
    return {
      "type": "FeatureCollection",
      "features": coordinatesArray.map((coordinates, index) => ({
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [coordinates]
          ]
        },
        "properties": {
          "indexFeature": index,
        }
      }))
    };
  };
  const createGeoJsonDataLotesVizinhos = (coordinatesArray) => {
    return {


      "type": "FeatureCollection",
      "features": coordinatesArray.map((coordinates, index) => ({
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            extractCoordinates2(coordinates)[0]
          ]
        },
        "properties": {
          "indexFeature": index,
        }
      }))
    };
  };

  const geoJsonData = createGeoJsonData(coordenadasPoligono);
  const geoJsonDataLoteVizinho = createGeoJsonDataLotesVizinhos([coordenadasLoteVizinho]);
  const geoJsonDataTestadas = createGeoJsonData(coordenadasPoligonoTestadas);
  const geoJsonDataCoberturas = createGeoJsonData(coordenadasPoligonoCoberturas);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      view: new View({
        center: [pontoCentralMap[0], pontoCentralMap[1]],
        zoom: 19,
        projection: 'EPSG:4326',
      }),
    });
    setNewMap(map)

    return () => {
      map.dispose();
    };
  }, [mapRef, pontoCentralMap, currentZoom, coordenadasPoligono, coordenadasPoligonoTestadas]);




  useEffect(() => {
    vectorLayerRefTestada.current = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonDataTestadas, {
          featureProjection: 'EPSG:4326',
        }),
      }),
      style: (feature, resolution) => {
        const index = feature.get('indexFeature');

        return new Style({
          fill: new Fill({
            color: colorsTestadas[index],
          }),
          stroke: new Stroke({
            color: colorsTestadas[index],
            width: 4,
          }),
        });
      },
      Type: 'testadas',
    })
    vectorLayerRefCoberturas.current = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonDataCoberturas, {
          featureProjection: 'EPSG:4326',
        }),
      }),
      style: (feature, resolution) => {
        const index = feature.get('indexFeature');
        const rgbColor = colorsCoberturas[index];
        const rgbaColor = `rgba(${rgbColor.substring(4, rgbColor.length - 1)}, 0.3)`;

        return new Style({
          fill: new Fill({
            color: rgbaColor,
          }),
          stroke: new Stroke({
            color: colorsCoberturas[index],
            width: 2,
          }),
        });
      },
      Type: 'coberturas',
    })
    vectorLayerRef.current = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonData, {
          featureProjection: 'EPSG:4326',
        }),
      }),
      style: (feature, resolution) => {
        const index = feature.get('indexFeature');
        const rgbColor = colorsLotes[index];
        const rgbaColor = `rgba(${rgbColor.substring(4, rgbColor.length - 1)}, 0.3)`;

        return new Style({
          fill: new Fill({
            color: rgbaColor,
          }),
          stroke: new Stroke({
            color: rgbColor,
            width: 1,
          }),
        });
      },
      Type: 'lotes',
    })
    vectorLayerRefLoteVizinho.current = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonDataLoteVizinho, {
          featureProjection: 'EPSG:4326',
        }),
      }),
      style: (feature, resolution) => {
        const index = feature.get('indexFeature');
        const rgbColor = colorsLotes[index];
        const rgbaColor = `rgba(1,1,1, 0.3)`;

        return new Style({
          fill: new Fill({
            color: rgbaColor,
          }),
          stroke: new Stroke({
            color: rgbaColor,
            width: 2,
          }),
        });
      },
      Type: 'lotesViznho',
    })

    if (!mapRef.current) {
      return;
    }

    // Verificar se 'lotes' ou 'testadas' estão no array radioButtonState
    const hasLotes = radioButtonState.includes('Lote');
    const hasTestadas = radioButtonState.includes('Testada');
    const hasLotesVizinhos = radioButtonState.includes('LotesVizinhos');
    const hasCoberturas = radioButtonState.includes('Cobertura');

    if (hasLotes) {
      newMap.addLayer(vectorLayerRef.current);
    }
    if (hasLotesVizinhos) {
      newMap.addLayer(vectorLayerRefLoteVizinho.current);
    }
    if (hasCoberturas) {
      newMap.addLayer(vectorLayerRefCoberturas.current);
    }
    if (hasTestadas) {
      newMap.addLayer(vectorLayerRefTestada.current);
    }

    return () => {
      if (hasLotes && vectorLayerRef.current) {
        newMap.removeLayer(vectorLayerRef.current);
      }
      if (hasTestadas && vectorLayerRefTestada.current) {
        newMap.removeLayer(vectorLayerRefTestada.current);
      }
      if (hasLotesVizinhos && vectorLayerRefLoteVizinho.current) {
        newMap.removeLayer(vectorLayerRefLoteVizinho.current);
      }
      if (hasCoberturas && vectorLayerRefCoberturas.current) {
        newMap.removeLayer(vectorLayerRefCoberturas.current);
      }
    };

  }, [radioButtonState, newMap]);



  return (
    <MapContextAdv.Provider
      value={{
        mapRef,
        colorsTestadas,
        colorsCoberturas,
        colorsCoberuturaStroke,
        setCurrentZoom,
        coordenadasPoligono,
        setCoordenadasPoligono,
        setCoordenadasLoteVizinho,
        pontoCentralMap,
        setPontoCentralMap,
        configurarCoordenada,
        configurarCoordenadaCentral,
        setTestadas,
        testadas,
        setCoordenadasPoligonoTestadas,
        coordenadasPoligonoTestadas,
        setRadioButtonState,
        extractCoordinates,
        arrayLoteCorbeturas,
        setArrayLoteCorbeturas,
        coordenadasPoligonoCoberturas,
        setCoordenadasPoligonoCoberturas,
        coberturas,
        setCoberturas
      }}
    >
      {children}
    </MapContextAdv.Provider>
  );
};

export { MapProviderAdv, MapContextAdv };
