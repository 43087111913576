export async function downloadFile(
  response: Response,
  filename: string
): Promise<void> {
  let blob = await response.blob();

  // Verifica se o arquivo é um CSV
  if (filename.endsWith(".csv")) {
    const text = await blob.text();
    const bom = "\uFEFF"; // BOM para UTF-8
    const utf8Blob = new Blob([bom + text], {
      type: "text/csv;charset=utf-8;",
    });
    blob = utf8Blob;
  }

  const urlFile = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = urlFile;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(urlFile);
}
