import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { defaults as defaultInteractions } from "ol/control";
import { Control } from "ol/control";
import { ConfigTile } from "./OrtoFotoConfig/ConfigTile";

class MapManager {
  constructor(center) {
    this.teste = new ConfigTile();

    this.newBaseLayer = new TileLayer({
      source: this.teste.getSource(),
      extent: this.teste.getExtent(),
    });

    // Criar botão personalizado
    const button = document.createElement("button");
    button.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style="width: 20px; height: 20px; fill: white">
        <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z"/>
      </svg>`;
    button.className = "ol-control-button";

    // Adicionar estilo CSS ao botão
    button.style.position = "absolute";
    button.style.top = "20px";
    button.style.right = "20px";
    button.style.zIndex = "1";
    button.style.padding = "8px";
    button.style.cursor = "pointer";
    button.style.backgroundColor = "#1976D2";
    button.style.border = "none";
    button.style.borderRadius = "4px";
    button.style.width = "36px";
    button.style.height = "36px";
    button.style.display = "flex";
    button.style.alignItems = "center";
    button.style.justifyContent = "center";

    // Criar o controle personalizado
    const customControl = new Control({
      element: button,
      target: undefined,
      className: "custom-control-position", // classe opcional para estilização adicional
    });

    let osmLayerAdded = false;
    let osmLayer = null;

    // Modificar o evento de clique do botão
    button.addEventListener("click", () => {
      if (!osmLayerAdded) {
        osmLayer = new TileLayer({
          source: new OSM(),
        });
        this.addLayer(osmLayer);
        osmLayerAdded = true;
      } else {
        this.removeLayer(osmLayer);
        osmLayerAdded = false;
      }
    });

    this.map = new Map({
      layers: [this.newBaseLayer],
      view: this.teste.getView(),
      controls: defaultInteractions({
        attribution: false,
        zoom: false,
        rotate: false,
      }).extend([customControl]),
    });

    this.layers = []; // Demais camadas gerenciadas
    this.observers = [];
    this.interactionsRemovidas = [];
    this.layersRemovidos = [];
    this.layers = [];
    this.observers = [];
  }

  setTarget(target) {
    this.map.setTarget(target);
  }

  addLayer(layer) {
    this.map.addLayer(layer);
    this.layers.push(layer);
  }

  removeLayer(layer) {
    this.map.removeLayer(layer);
    console.log(this.layers);
    this.layers = this.layers.filter((l) => l !== layer);
  }

  addObserver(observerLayers) {
    this.observers.push(observerLayers);
  }

  removeObserver(observerLayers) {
    this.observers = this.observers.filter((obs) => obs !== observerLayers);
  }

  // Função para ajustar o ponto central do mapa
  setCenter(center) {
    this.map.getView().setCenter(center);
  }

  // Função para ajustar o mapa nos arredores dos poligonos
  setBoudingBox(extent) {
    this.map.getView().fit(extent, {
      size: this.map.getSize(), // Tamanho atual do mapa
      padding: [20, 20, 20, 20], // Adicionar um padding (opcional)
      maxZoom: 8, // Zoom máximo para evitar exagero de aproximação
    });
  }

  // Função para ajustar o nível de zoom
  setZoom(zoom) {
    this.map.getView().setZoom(zoom);
  }

  getLayers() {
    return this.layers;
  }

  getMap() {
    return this.map;
  }
}

export default MapManager;
