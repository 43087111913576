import React, { useEffect, useState, useRef } from "react";
import { Modal, Box, Button, IconButton, Tab, Tabs } from "@mui/material";
import TabMapa from "./Tabs/TabMapa";
import { Close } from "@material-ui/icons";
import { Api } from "../../../../services/Api";
import { ContextModalLote } from "./ContextModalLote/ContextModalLote";
import { InputProvider } from "./ContextModalLote/ContextModalLote";
import { TabImagens } from "./Tabs/TabImagens";
import TabLote from "./Tabs/TabLote";
import TabUnidadeImobiliaria from "./Tabs/TabUnidadeImobiliaria";
import { TabAreaCoberta } from "./Tabs/TabAreaCoberta";
import { fnArrayUnidades, fnDadosLotes, fnUnidadeImobiliaria } from "./utilsModal";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const ModalLote = ({
    open,
    onClose,
    returnLoteColeta,
    resetLoteLayer,
}) => {
    const { getInputs, resetInputValues, setLoteData } = ContextModalLote();
    const [dispachSave, setDispachSave] = useState(false);

    const [legenda, setLegenda] = useState([]);
    const [storage, setStorage] = useState(null);
    const [storageUnidades, setStorageUnidades] = useState([]);
    const [storageMapa, setStorageMapa] = useState(null);
    const [value, setValue] = useState(0);
    const [coberturasData, setCoberturasData] = useState([]);
    const [inputsInit, setInputsInit] = useState({});
    const [imgInit, setImgInit] = useState({});
    const unidadesAreaCoberta = useRef([]);
    const unidadesDataRef = useRef([]);
    const loteDataRef = useRef({});
    const loteImgRef = useRef({});

    const requestLote = async () => {
        try {
            if (returnLoteColeta) {
                const objetoLote = [{ coordenadas: returnLoteColeta.ID.geom, id: {} }];
                const { data } = await Api.getLoteForId(returnLoteColeta.ID.id);
                const unidades = fnUnidadeImobiliaria(data.unidades)
                const arrayUnidades = fnArrayUnidades(data.unidades)
                const obj3 = fnDadosLotes(data)
                setCoberturasData(unidades[0]?.areaCoberta);
                setInputsInit(data.lote);
                setImgInit(data.fotos)
                setLoteData(obj3); // Adicione esta linha para armazenar os dados do obj3
                setStorageMapa(data);
                setStorage(objetoLote);
                setStorageUnidades(arrayUnidades);
            }

        } catch (error) {
            console.error("Error in fnDadosLotes:", error);
            return null;
        }


    };

    useEffect(() => {
        if (returnLoteColeta) { requestLote() }
    }, [returnLoteColeta]); // Executa apenas quando returnLoteColeta estiver disponível

    const getCoberturas = (item) => { setLegenda(item[0]) };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        resetInputValues();
        onClose();
    };
    const handleSave = async () => {
        setDispachSave(true);
        const inputs = getInputs();
        if (!inputs) return;

        await new Promise((resolve) => setTimeout(resolve, 0));

        const unidadesAtualizadas = storageUnidades.map((item) => ({
            ...item,
            ...unidadesDataRef.current.find((u) => u.id === item.id),
        }));

        const pois = unidadesDataRef.current
            .filter((u) => u.tipoInteresse && u.nomeInteresse)
            .map((u) => ({
                id: u.idInteresse || null,
                unidadeId: u.id,
                tipo: u.tipoInteresse,
                nome: u.nomeInteresse,
            }));
        const dadosParaSalvar = {
            ...inputs,
            lote: { ...inputsInit, ...loteDataRef.current, id: inputs.id },
            coberturas: unidadesAreaCoberta.current,
            unidades: unidadesAtualizadas,
            pois: pois,
        };

        try {
            await Api.getSaveColetaImagens({
                loteId: inputs.id,
                imagensInfo: loteImgRef.current,
            });
            alert("Sucesso ao salvar dados!");
        } catch (erro) {
            alert("Erro ao salvar imagens da coleta:", erro);
            console.error("Erro ao salvar imagens da coleta:", erro);
        }
        try {
            const response = await Api.getSaveColeta(dadosParaSalvar);
            if (response.status < 300) {
                alert("Sucesso ao salvar dados!");
            } else {
                throw new Error("Resposta do servidor não foi bem-sucedida");
            }
            handleClose();
        } catch (erro) {
            alert("Erro ao salvar dados!");
            console.error("Erro ao salvar a coleta:", erro);
        }
        resetLoteLayer();
    };

    const handleCoberturasUpdate = (newCoberturas) => {
        const coberturasFiltradas = newCoberturas
            .map((cobertura) => ({
                ...cobertura,
                andares: cobertura.andares === "" ? undefined : cobertura.andares,
                beiral: cobertura.beiral === "" ? undefined : cobertura.beiral,
            }))
            .filter(
                (cobertura) =>
                    cobertura.andares !== undefined && cobertura.beiral !== undefined
            );
        unidadesAreaCoberta.current = coberturasFiltradas;
    };

    const handleUnidadesUpdate = (newUnidades) => {
        unidadesDataRef.current = newUnidades;
    };

    const handleLoteUpdate = (newLoteData) => {
        loteDataRef.current = newLoteData;
    };

    const handleImgUpdate = (newLoteData) => {
        loteImgRef.current = newLoteData;
    };

    const [imageUrl, setImageUrl] = useState(null);

    const onClickImage = (img) => {
        setImageUrl(img);
    };

    return (
        <InputProvider>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        width: "90%",
                        height: "88vh",
                        bgcolor: "background.paper",
                        py: 2,
                        m: "auto",
                        mt: 5,
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        position: "relative", // Necessário para posicionar o botão de fechar
                    }}
                >
                    {/* Ícone de fechar no canto superior direito */}
                    <div
                        style={{
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                paddingInline: 20,
                            }}
                        >
                            {storage && (
                                <TabMapa
                                    geometriaLoteColeta={storage}
                                    responseLoteForId={storageMapa}
                                    getCoberturas={getCoberturas}
                                    url={imageUrl}
                                />
                            )}
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    top: 10,
                                    right: 5,
                                    color: "#1769aa",
                                    zIndex: 2,
                                }}
                            >
                                <Close />
                            </IconButton>
                            <div style={{ height: "60vh", paddingRight: 10 }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    sx={{ marginBottom: "10px" }}
                                >
                                    <Tab label='Lote' {...a11yProps(0)} />
                                    <Tab label='Unidade Imobiliária' {...a11yProps(1)} />
                                    {legenda.length > 0 && (
                                        <Tab label='Coberturas' {...a11yProps(2)} />
                                    )}
                                    <Tab
                                        label='Imagens'
                                        {...a11yProps(legenda.length > 0 ? 3 : 2)}
                                    />
                                </Tabs>

                                <div style={{ display: value === 0 ? "block" : "none" }}>
                                    <TabLote
                                        save={dispachSave}
                                        inputsInit={inputsInit}
                                        onUpdate={handleLoteUpdate}
                                    />
                                </div>

                                <div style={{ display: value === 1 ? "block" : "none" }}>
                                    <TabUnidadeImobiliaria
                                        save={dispachSave}
                                        unidades={storageUnidades}
                                        unidadesDataAtual={unidadesDataRef.current}
                                        onUpdate={handleUnidadesUpdate}
                                    />
                                </div>

                                {legenda.length > 0 && (
                                    <div style={{ display: value === 2 ? "block" : "none" }}>
                                        <TabAreaCoberta
                                            save={dispachSave}
                                            coberturas={legenda}
                                            onUpdate={handleCoberturasUpdate}
                                            coberturasExistentes={coberturasData}
                                        />
                                    </div>
                                )}

                                <div
                                    style={{
                                        display:
                                            legenda.length > 0
                                                ? value === 3
                                                    ? "block"
                                                    : "none"
                                                : value === 2
                                                    ? "block"
                                                    : "none",
                                    }}
                                >
                                    <TabImagens
                                        dados={imgInit}
                                        id={returnLoteColeta.ID.id}
                                        onUpdate={handleImgUpdate}
                                        onClickImage={onClickImage}
                                    // imgInit={imgInit}
                                    />
                                </div>
                            </div>
                            <Button
                                sx={{
                                    position: "absolute",
                                    width: 35,
                                    paddingX: 6,
                                    bottom: 10,
                                    right: 5,
                                }}
                                onClick={handleSave}
                                variant='contained'
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </InputProvider>
    );
};
