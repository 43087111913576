import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Fab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TextField, TablePagination, IconButton } from '@mui/material';
import "./AlterarProprietario.css";
import ModalCadastro from "./ModalCadastro/ModalCadastro";
import { Api } from "../../services/Api.js";
import { faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons";


const AlterarProprietario = () => {
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [addProprietario, setAddProprietario] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm_CPF_CNPJ, setSearchTerm_CPF_CNPJ] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddProprietario, setShowAddProprietario] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [searching, setSearching] = useState(false); //Adiciona o procurando durante a procura da API
  const [proprietarioParaEditar, setProprietarioParaEditar] = useState(null);

  //Configura a lista de acordo com o tamanho da tela
  useEffect(() => {
    if (window.innerHeight > 750) {
      const numberPerPage = 12;
      setRowsPerPage(numberPerPage)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSearching(true);
        const data = await Api.proprietariosCadastrados(localStorage.getItem("infoTotalProprietarios"));
        // Remover elementos com nome nulo
        const filteredData = data.data.result.items.filter((proprietario) => proprietario.nome !== null);
        // Função de comparação personalizada para ignorar espaços no nome
        console.log(data.data.result);
        const compareNames = (a, b) => {
          const nameA = a.nome ? a.nome.replace(/\s/g, '') : '';
          const nameB = b.nome ? b.nome.replace(/\s/g, '') : '';
          return nameA.localeCompare(nameB);
        };

        setInitialData(filteredData.sort(compareNames));
      } catch (error) {
        console.log('Deu erro:', error);
      }
      setSearching(false);
    };

    fetchData();
  }, [addProprietario]);

  useEffect(() => {
    if (searchTerm === '' && searchTerm_CPF_CNPJ === '') {
      const filteredProprietarios = initialData
      setFilteredData(filteredProprietarios);
    }
  }, [initialData, searchTerm, searchTerm_CPF_CNPJ]);

  //Atualiza a lista com o Proprietário adiconado
  function AtualizaListaProprietários() {
    const totalProprietarios = parseInt(localStorage.getItem("infoTotalProprietarios")) + 1;
    localStorage.setItem("infoTotalProprietarios", totalProprietarios);
    setAddProprietario(!addProprietario);
  }

  function activeFiltered() {
    let filteredProprietarios = initialData.filter((proprietario) => {
      const nomeProprietario = proprietario.nome ? proprietario.nome.toLowerCase() : ""; //Passa para caixa baixa
      const cpfCnpjPorprietario = proprietario.cpfCnpj ? proprietario.cpfCnpj.toLowerCase() : ""; //Passa para caixa baixa
      return nomeProprietario.startsWith(searchTerm.toLowerCase()) && cpfCnpjPorprietario.startsWith(searchTerm_CPF_CNPJ.toLowerCase()); //Retorna os 2 filtros juntos
    });
    setFilteredData(filteredProprietarios);
  }

  //Enter envia
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      activeFiltered();
    }
  };

  const toggleAddProprietario = () => {
    setShowAddProprietario(!showAddProprietario);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchProprietario = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch_CPF_CNPJ = (e) => {
    setSearchTerm_CPF_CNPJ(e.target.value);
    setCurrentPage(0);
  };

  const handleEdit = async (proprietario) => {
    try {
      const resposta = await Api.searchProprietario({ name: proprietario.nome, cpfCnpj: proprietario.cpfCnpj });
      setProprietarioParaEditar(resposta.data);
      toggleAddProprietario();
    } catch (erro) {
      console.error('Erro ao buscar proprietário:', erro);
    }
  };

  const handleDelete = async (proprietario) => {
    if (window.confirm(`Tem certeza que deseja excluir o proprietário ${proprietario.nome}?`)) {
      try {
        await Api.deleteProprietario(proprietario.id);
        // Atualiza a lista de proprietários após a exclusão
        setAddProprietario(!addProprietario);
      } catch (erro) {
        console.error('Erro ao excluir proprietário:', erro);
      }
    }
  };
  const formatCPFOrCNPJ = (value) => {
    // Remove todos os caracteres inválidos, mas mantém os permitidos
    const onlyAllowed = value.replace(/[^0-9.,/\-]/g, "");

    // Remove os caracteres extras para mascarar o CPF ou CNPJ
    const onlyNums = onlyAllowed.replace(/[.,/\-]/g, "");

    // Aplica a máscara de CPF (máximo 11 dígitos)
    if (onlyNums.length <= 11) {
      return onlyNums
        .replace(/^(\d{3})(\d)/, "$1.$2") // Primeiro ponto
        .replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3") // Segundo ponto
        .replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3-$4") // Hífen
        .slice(0, 14); // Limita ao tamanho do CPF
    }

    // Aplica a máscara de CNPJ (máximo 14 dígitos)
    return onlyNums
      .replace(/^(\d{2})(\d)/, "$1.$2") // Primeiro ponto
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") // Segundo ponto
      .replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3/$4") // Barra
      .replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, "$1.$2.$3/$4-$5") // Hífen
      .slice(0, 18); // Limita ao tamanho do CNPJ
  };

  const handleChange = (event) => {
    const input = event.target;
    const previousValue = searchTerm_CPF_CNPJ; // Valor antes da alteração
    const newValue = input.value;

    // Log da posição atual do cursor
    console.log('Posição inicial do cursor:', input.selectionStart);

    // Formata o valor
    const formattedValue = formatCPFOrCNPJ(newValue);

    // Calcula o deslocamento do cursor
    let addCursor = 0;

    // Ajusta o deslocamento para garantir a posição correta após a formatação
    if (previousValue.length < newValue.length) {
      const addedCharacter = newValue[input.selectionStart - 1];
      if (addedCharacter === '.' || addedCharacter === '-' || addedCharacter === '/') {
        addCursor += 1;
      }
    }
    let cursorOffset = formattedValue.length - newValue.length + addCursor;

    // Atualiza o estado
    setSearchTerm_CPF_CNPJ(formattedValue);

    const position = input.selectionStart + cursorOffset
    setTimeout(() => {
      console.log(position);
      console.log('Posição do cursor após formatação:', input.selectionStart + cursorOffset + '\n.'); // Log da posição do cursor após a formatação
      input.setSelectionRange(position, position);
    }, 0);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      padding: '3rem',
      gap: '3rem',
      boxSizing: 'border-box'
    }}>
      <div style={{ display: 'flex', width: '100%', gap: '3rem', paddingInline: 50 }}>
        <TextField
          className="input-pesquisa"
          size="small"
          label="Pesquisar Proprietário"
          value={searchTerm}
          onChange={handleSearchProprietario}
          sx={{ width: '100%', backgroundColor: 'white' }}
          onKeyPress={(event) => handleKeyPress(event)}
        />

        <TextField
          value={searchTerm_CPF_CNPJ}
          onChange={handleChange}
          size="small"
          label="CPF"
          placeholder="000.000.000-00"
          sx={{ width: "100%", backgroundColor: "white" }}
          inputProps={{ maxLength: 18 }}
        />
      </div>

      <div style={{ display: 'flex', width: '100%', height: '100%', boxSizing: 'border-box', paddingInline: '2rem' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead >
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>Proprietário</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>CPF/CNPJ</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                .map((proprietario, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: '11px', maxWidth: '130px' }}>{proprietario.nome}</TableCell>
                    <TableCell sx={{ fontSize: '11px', maxWidth: '30px' }}>{proprietario.cpfCnpj}</TableCell>
                    <TableCell sx={{ fontSize: '11px', width: '100px' }}>
                      <IconButton size="small" onClick={() => handleEdit(proprietario)}>
                        <FontAwesomeIcon icon={faEdit} style={{ fontSize: '0.85rem', color: '#1976D2' }} />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleDelete(proprietario)}>
                        <FontAwesomeIcon icon={faTrash} style={{ fontSize: '0.85rem', color: 'red' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TableContainer component={Paper} >

            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </TableContainer>
      </div>

      <div className="barra-direita-alterarproprietario">
        <Fab className="custom-buttom" onClick={activeFiltered}>
          {searching ? (
            <FontAwesomeIcon className="icons" icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon className="icons" icon={faMagnifyingGlass} />
          )}
        </Fab>
        <Fab onClick={() => {
          setProprietarioParaEditar(null)
          toggleAddProprietario()
        }} sx={{ margin: '5px', fontSize: '20px' }} color="primary">
          <FontAwesomeIcon icon={faPlus} />
        </Fab>
      </div>

      {showAddProprietario && (
        <section style={{ position: 'relative' }}>
          <ModalCadastro
            onClose={toggleAddProprietario}
            AtualizaListaProprietários={AtualizaListaProprietários}
            proprietarioParaEditar={proprietarioParaEditar}
          />
        </section>
      )}
    </div>
  );
};

export default AlterarProprietario;