import jsPDF from "jspdf";
import axios from "axios";

import "ol/ol.css";
import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { OSM } from "ol/source.js";
import { Tile as TileLayer } from "ol/layer.js";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import { Style, Fill, Stroke, Text } from "ol/style";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { getLength as getLineLength } from "ol/sphere";
import MultiPolygon from "ol/geom/MultiPolygon";
import { ScaleLine, defaults as defaultControls } from "ol/control.js";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import html2canvas from "html2canvas";
import { fnAdicionarLabels } from "./utilsMap/fnAdicionarLabels";
import { fnAddAreaLabel } from "./utilsMap/fnAddAreaLabel";
import { createLoteVizinho, createSource, mapLote } from "./MapQuadra";
import { fnAddLabel } from "./utilsMap/fnAddLabel";

proj4.defs(
  "EPSG:31983",
  "+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

const generateMapImage = async (data) => {
  const tempMapElement = document.createElement("div");
  tempMapElement.style.cssText = `
      width: 100%;
      height: 100%;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    `;
  document.body.appendChild(tempMapElement);

  // Container principal com duas colunas
  const mapsContainer = document.createElement("div");
  mapsContainer.style.cssText = `
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    `;
  tempMapElement.appendChild(mapsContainer);

  // Coluna 1: Mapa grande
  const map1Wrapper = document.createElement("div");
  map1Wrapper.style.cssText = `
      width: 100%;
      height: 100%;
      position: relative;
    `;
  // Coluna 2: Container dos mapas menores
  const smallMapsContainer = document.createElement("div");
  smallMapsContainer.style.cssText = `
      display: grid;
      grid-template-rows: 1fr 1fr; /* Duas linhas iguais */
      gap: 10px;
      height: 100%; /* Ocupa toda a altura */
    `;

  // Containers individuais dos mapas
  const map1Container = document.createElement("div");
  map1Container.style.cssText = `
      width: 100%;
      height: 100%;
      border: 1px solid #727272;
    
    `;

  const map2Container = document.createElement("div");
  map2Container.style.cssText = `
      width: 100%;
      height: 100%;
      border: 1px solid #727272;
    
    `;

  const map3Container = document.createElement("div");
  map3Container.style.cssText = `
      width: 100%;
      height: 100%;
      border: 1px solid #727272;
    
    `;

  // Monta a estrutura
  map1Wrapper.appendChild(map1Container);
  smallMapsContainer.appendChild(map2Container);
  smallMapsContainer.appendChild(map3Container);

  mapsContainer.appendChild(map1Wrapper);
  mapsContainer.appendChild(smallMapsContainer);

  // Array com os containers e suas configurações
  const mapConfigs = [
    { container: map1Container, handler: handleFirstMap },
    { container: map2Container, handler: handleSecondMap },
    { container: map3Container, handler: handleThirdMap },
  ];

  let center = [452673.985418907, 7520803.474062003];
  let rotation = 0;
  if (data.data.lote) {
    const newSource = new VectorSource({
      features: new GeoJSON().readFeatures(data.data.lote.geom, {
        dataProjection: "EPSG:31983",
        featureProjection: "EPSG:31983",
      }),
    });
    const extent = newSource.getExtent();

    const dx = extent[2] - extent[0];
    const dy = extent[3] - extent[1];

    rotation = Math.atan2(dy, dx);
    center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
  }

  // Cria os 3 mapas
  const maps = mapConfigs.map(({ container, handler }, index) => {
    const newMap = new Map({
      target: container,
      layers:
        index == 2
          ? []
          : [
              new TileLayer({
                source: new OSM(),
              }),
            ],
      view: new View({
        center: center,
        zoom: 13,
        projection: "EPSG:31983",
        resolutions: resolutions,
        maxZoom: 20,
        minZoom: 0,
        constrainResolution: true,
      }),
      controls: defaultControls({ zoom: false }),
    });
    const scaleLineControl = new ScaleLine({
      units: "metric",
      bar: true,
      steps: 4,
      text: true,
      minWidth: 140,
      maxWidth: 140,
      dpi: 96,
    });
    newMap.addControl(scaleLineControl);

    // Configura o mapa usando o handler específico
    if (data && data.data.logradouro) {
      const logradouro = data.data.logradouro;

      const check = newMap
        .getLayers()
        .getArray()
        .find((layer) => {
          return layer.getProperties().name === "logradouro-";
        });

      if (!check) {
        const newSource = new VectorSource({
          features: new GeoJSON().readFeatures(logradouro.geom, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
          }),
        });

        const vectorLayer = new VectorLayer({
          source: newSource,
          style: new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "#000000",
              width: 2,
            }),
            text: new Text({
              text: logradouro.nome || "",
              font: "26px Calibri,sans-serif",
              fill: new Fill({
                color: "#000",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 3,
              }),
              placement: "line",
              maxAngle: 30,
            }),
          }),
        });

        vectorLayer.setProperties({
          name: "logradouro-",
        });

        newMap.addLayer(vectorLayer);
      }
    }
    handler(newMap, data);

    return newMap;
  });

  // Força atualização do tamanho após renderização
  maps.forEach((map) => {
    map.updateSize();
    map.renderSync();
  });

  // Força os mapas a atualizarem seus tamanhos
  maps.forEach((map) => map.updateSize());
  // Aguarda os mapas carregarem
  await Promise.all(
    maps.map(
      (map) =>
        new Promise((resolve) => {
          map.once("rendercomplete", () => {
            setTimeout(resolve, 100); // Aguarda 1 segundo após renderização
          });
          map.renderSync();
          map.updateSize();
        })
    )
  );

  try {
    const canvas = await html2canvas(tempMapElement, {
      useCORS: true,
      scale: 2,
      logging: true,
      allowTaint: true,
      foreignObjectRendering: true,
    });
    // Limpa o elemento temporário
    document.body.removeChild(tempMapElement);
    //@ts-ignore
    maps.forEach((map) => map.setTarget(null));
    return canvas.toDataURL("image/png");
  } catch (error) {
    document.body.removeChild(tempMapElement);
    //@ts-ignore
    maps.forEach((map) => map.setTarget(null));
    return null;
  }
};

// Funções auxiliares para configurar cada mapa (adaptadas do MapRender.jsx)
const handleFirstMap = (map, data) => {
  if (data.data && data.data.lote) {
    const newSource = new VectorSource({
      features: new GeoJSON().readFeatures(data.data.lote.geom, {
        dataProjection: "EPSG:31983",
        featureProjection: "EPSG:31983",
      }),
    });

    const vectorLayer = new VectorLayer({
      source: newSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(254,234,55,1)",
        }),
        stroke: new Stroke({
          color: "#0a0a0a",
          width: 1,
        }),
        zIndex: 1,
      }),
    });
    map.addLayer(vectorLayer);

    const labels = fnAdicionarLabels(newSource.getFeatures());
    labels.forEach((labelLayer) => {
      map.addLayer(labelLayer);
    });

    const coordCenter = newSource.getExtent();
    const center = [
      (coordCenter[0] + coordCenter[2]) / 2,
      (coordCenter[1] + coordCenter[3]) / 2,
    ];

    // Calcula o tamanho do lote
    const extent = newSource.getExtent();
    const width = extent[2] - extent[0];
    const height = extent[3] - extent[1];
    const size = Math.max(width, height);

    // Ajusta o zoom baseado no tamanho
    //@ts-ignore
    const resolution = size / Math.min(map.getSize()[0], map.getSize()[1]);
    const zoom = map.getView().getZoomForResolution(resolution);

    // Centraliza e aplica o zoom
    map.getView().fit(extent, {
      maxZoom: zoom ? zoom : 15,
    });

    // const area = new VectorLayer({
    //   source: new VectorSource({
    //     features: [
    //       new Feature({
    //         geometry: new Point(center),
    //         name: `${getLineLength(
    //           new MultiPolygon(
    //             //@ts-ignore
    //             newSource.getFeatures()[0].getGeometry().getCoordinates()
    //           ),
    //           {
    //             projection: "EPSG:31983",
    //           }
    //         ).toFixed(2)} m²`,
    //       }),
    //     ],
    //   }),
    //   style: new Style({
    //     text: new Text({
    //       text: `${getLineLength(
    //         new MultiPolygon(
    //           //@ts-ignore
    //           newSource.getFeatures()[0].getGeometry().getCoordinates()
    //         ),
    //         {
    //           projection: "EPSG:31983",
    //         }
    //       ).toFixed(2)} m²`,
    //       font: "18px Calibri,sans-serif",
    //       fill: new Fill({
    //         color: "#0000ff",
    //       }),
    //       stroke: new Stroke({
    //         color: "#fff",
    //         width: 3,
    //       }),
    //     }),
    //   }),
    // });

    map.addLayer(fnAddAreaLabel(newSource));
  }

  if (data.data && data.data.areaCoberta) {
    data.data.areaCoberta.map((cobertura) => {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(cobertura.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const vectorLayer = new VectorLayer({
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(128,128,128,0.3)",
          }),
          stroke: new Stroke({
            color: "#0a0a0a",
            width: 1,
          }),
          zIndex: 2,
        }),
      });

      map.addLayer(vectorLayer);

      map.getView().setZoom(15);

      newSource.getFeatures().forEach((feature) => {
        const geometry = feature.getGeometry();

        if (geometry?.getType() === "Polygon") {
          //@ts-ignore
          const coordinates = geometry.getCoordinates()[0];

          for (let i = 0; i < coordinates.length - 1; i++) {
            const start = coordinates[i];
            const end = coordinates[i + 1];

            const distance = getLineLength(new LineString([start, end]), {
              projection: "EPSG:31983",
            });

            const midPoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

            const label = new VectorLayer({
              source: new VectorSource({
                features: [
                  new Feature({
                    geometry: new Point(midPoint),
                    name: `${distance.toFixed(2)} m`,
                  }),
                ],
              }),
              style: new Style({
                text: new Text({
                  text: `${distance.toFixed(2)} m`,
                  font: "12px Calibri,sans-serif",
                  fill: new Fill({
                    color: "#000",
                  }),
                  stroke: new Stroke({
                    color: "#fff",
                    width: 3,
                  }),
                }),
              }),
              zIndex: 10,
            });

            map.addLayer(label);
          }
        }
      });
    });
  }

  if (data.data && data.data.testadaPrincipal) {
    const testada = data.data.testadaPrincipal;
    const newSource = new VectorSource({
      features: new GeoJSON().readFeatures(testada.geom, {
        dataProjection: "EPSG:31983",
        featureProjection: "EPSG:31983",
      }),
    });

    const vectorLayer = new VectorLayer({
      source: newSource,
      style: new Style({
        stroke: new Stroke({
          color: "#0000ff",
          width: 4,
        }),
        zIndex: 3,
      }),
    });

    map.addLayer(vectorLayer);

    const extensao = testada.extensao || 0;

    const coordinates = testada.geom.coordinates[0];
    const start = coordinates[0];
    const end = coordinates[1];
    const midPoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

    const label = new VectorLayer({
      source: new VectorSource({
        features: [
          new Feature({
            geometry: new Point(midPoint),
            name: `${extensao.toFixed(2)} m`,
          }),
        ],
      }),
      style: new Style({
        text: new Text({
          text: `${extensao.toFixed(2)} m`,
          font: "18px Calibri,sans-serif",
          fill: new Fill({
            color: "#0000ff",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 3,
          }),
          offsetY: 20,
        }),
      }),
    });

    map.addLayer(label);
  }
};

const handleSecondMap = (map, data) => {
  if (data.data && data.data.lote) {
    const newSource = createSource(data.data.lote.geom);
    // const newSource2 = createSource(data.data.loteVizinhos[0].geom.coordinates);
    // map.addLayer(mapLote(newSource2));
    map.addLayer(mapLote(newSource));
    map.addLayer(fnAddLabel(newSource, data.data.lote.id, "#0a0a0a"));
    data.data.loteVizinhos.map((coord, index) => {
      const newSource = createSource(coord.geom);
      map.addLayer(createLoteVizinho(newSource));
      map.addLayer(fnAddLabel(newSource, data.data.lote.id + index, "#0a0a0a"));
    });

    console.log(data.data);

    // Calcula o tamanho do lote
    const extent = newSource.getExtent();
    const width = extent[2] - extent[0];
    const height = extent[3] - extent[1];
    const size = Math.max(width, height);

    // Ajusta o zoom baseado no tamanho
    //@ts-ignore
    const resolution = size / Math.min(map.getSize()[0], map.getSize()[1]);
    const zoom = map.getView().getZoomForResolution(resolution);

    // Centraliza e aplica o zoom
    map.getView().fit(extent, {
      padding: [50, 50, 50, 50],
      minResolution: resolution * 0.2, // Ajusta para zoom um pouco mais próximo
      maxZoom: zoom,
    });
    const view = map.getView(); // Obtém a `View` do mapa
    view.setZoom(14); // Define o nível de zoom
  }
};

const handleThirdMap = (map, data) => {
  if (data && data.data.lote && data.data.lote.geom) {
    const loteSource = new VectorSource({
      features: new GeoJSON().readFeatures(data.data.lote.geom, {
        dataProjection: "EPSG:31983",
        featureProjection: "EPSG:31983",
      }),
    });

    const loteLayer = new VectorLayer({
      source: loteSource,
      style: new Style({
        fill: new Fill({
          color: "rgba(254,234,55,0.5)",
        }),
        stroke: new Stroke({
          color: "#0a0a0a",
          width: 1,
        }),
      }),
    });

    map.addLayer(loteLayer);

    // Calcula o tamanho do lote
    const extent = loteSource.getExtent();
    const width = extent[2] - extent[0];
    const height = extent[3] - extent[1];
    const size = Math.max(width, height);

    // Ajusta o zoom baseado no tamanho
    //@ts-ignore
    const resolution = size / Math.min(map.getSize()[0], map.getSize()[1]);
    const zoom = map.getView().getZoomForResolution(resolution);

    // Aplica o zoom
    map.getView().fit(extent, {
      padding: [10, 10, 10, 10],
      minResolution: resolution * 0.7,
      maxZoom: 18,
    });
  }
};

export function convertCaseString(word, type) {
  if (word == null) {
    return "-";
  }

  if (
    type == "Área total" ||
    type == "Fração ideal" ||
    type == "Área principal" ||
    type == "Área das dependentes"
  ) {
    word = parseFloat(word).toFixed(2);
    return word.toString();
  }

  word = word.toString();
  return word.toUpperCase();
}

export const generatePdf = async (data, options) => {
  const fontArialNormalData = "./fonts/ARIAL.TTF";
  const fontArialBoldData = "./fonts/ARIALBD.TTF";
  const logoCaxambu = "./prefecture-icons/icon_itajuba.png";
  const y = 10,
    a = 20,
    b = 20,
    d = -10,
    e = -10;
  const currentdate = new Date();
  const today =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " - " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  function sanitizeValue(value, fallback = "-") {
    if (
      value === "" || // String vazia
      value === " " || // String com espaço
      value == null || // null ou undefined
      (typeof value === "number" && isNaN(value)) // NaN
    ) {
      return fallback;
    }
    return value;
  }

  doc.addFont(fontArialNormalData, "Arial", "normal");
  doc.addFont(fontArialBoldData, "Arial", "bold");

  const altura =
    doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  const largura =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const createHeader = () => {
    doc.line(10, y, 200, y);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);
    doc.setFont("Arial", "normal");
    doc.text("PREFEITURA MUNICIPAL DE ITAJUBÁ", 12, y + 4);
    doc.text("Emissão " + today, 198, y + 4, { align: "right" });
    doc.line(10, y + 6, 200, y + 6);
    doc.addImage(logoCaxambu, "png", 12, y + 10, 14, 15, "Itajuba Logo");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("Arial", "bold");
    doc.text("PREFEITURA MUNICIPAL DE ITAJUBÁ", 28, y + 15);
    doc.setFontSize(8);
    doc.setFont("Arial", "bold");
    doc.text("ESTADO DE MINAS GERAIS", 28, y + 20);
  };

  const createFooter = () => {
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(8);
    doc.setFont("Arial", "normal");
    const logradouroNome = logradouro.nome
      ? convertCaseString(logradouro.nome, "Situação")
      : "Sem registro!";

    const enderecoNumero = endereco.numero?.toString() || "Sem registro!";

    doc.text(`RUA ${logradouroNome}, ${enderecoNumero}`, 12, altura - 15 + 4);

    doc.text(
      convertCaseString(proprietario["nome"], "Situação") + " - " + today,
      198,
      altura - 15 + 4,
      { align: "right" }
    );
    doc.line(10, altura - 10, 200, altura - 10);
    const totalPages = doc.getNumberOfPages();
    doc.text(`${totalPages}`, 198, altura - 5, {
      align: "right",
    });
  };

  createHeader();

  // --------
  doc.setFontSize(8);
  doc.setFont("Arial", "bold");
  doc.text("Dados Gerais", 12, a + 30);
  doc.setFontSize(10);
  doc.setFont("Arial", "bold");
  doc.text("Boletim de Inscrição Cadastral - BIC", 200, a + 30, {
    align: "right",
  });
  doc.line(10, a + 32, 200, a + 32);

  const unidade = data.data.unidade; // Acessando a unidade corretamente
  const lote = data.data.lote; // Acessando o lote corretamente
  const proprietario = data.data.proprietario; // Acessando o proprietário corretamente
  const logradouro = data.data.logradouro; // Acessando o logradouro corretamente
  const endereco = data.data.endereco;

  doc.setFontSize(8);
  doc.setFont("Arial", "normal");
  doc.text("INSCRIÇÃO/REDUZIDO", 12, a + 37);
  doc.setFont("Arial", "bold");
  if (unidade.length != 0)
    doc.text(
      convertCaseString(
        //@ts-ignore
        data.data.inscricaoImovel + " / " + unidade.id,
        "Inscrição"
      ),
      45,
      a + 37
    );
  else
    doc.text(
      convertCaseString(data.data.inscricaoImovel + "/" + lote.id, "Inscrição"),
      45,
      a + 37
    );

  doc.setFont("Arial", "normal");
  doc.text("PROPRIETÁRIO", 12, a + 42);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(proprietario["nome"] || "Sem registro!", "Contribuinte"),
    45,
    a + 42
  );

  doc.setFont("Arial", "normal");
  doc.text("CPF/CNPJ", 12, a + 47);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(
      String(proprietario.cpfCnpj || "Sem registro!"),
      "CPF/CNPJ"
    ),
    45,
    a + 47
  );

  doc.setFont("Arial", "normal");
  doc.text("ENDEREÇO", 12, a + 52);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(proprietario["endereco"] || "Sem registro!", "ENDEREÇO"),
    45,
    a + 52
  );

  //----------------------------
  doc.setFontSize(8);
  doc.setFont("Arial", "bold");
  doc.text("Localização do imóvel", 12, b + 58);
  doc.line(10, b + 60, 200, b + 60);

  doc.setFont("Arial", "normal");
  doc.text("LOGRADOURO", 12, b + 65);
  doc.setFont("Arial", "bold");
  doc.text(convertCaseString(logradouro["nome"], "Logradouro"), 45, b + 65);
  doc.setFont("Arial", "normal");
  doc.text("NÚMERO", 130, b + 65);
  doc.setFont("Arial", "bold");
  doc.text(endereco["numero"].toString(), 155, b + 65);

  doc.setFont("Arial", "normal");
  doc.text("BAIRRO", 12, b + 70);
  doc.setFont("Arial", "bold");
  doc.text(convertCaseString(endereco["bairro"], "Bairro"), 45, b + 70);
  doc.setFont("Arial", "normal");
  doc.text("SETOR/QUADRA", 130, b + 70);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(
      lote["setorCod"] + " / " + lote["quadraCod"],
      "Setor/Quadra"
    ),
    155,
    b + 70
  );

  doc.setFont("Arial", "normal");
  doc.text("COMPLEMENTO", 12, b + 75);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(
      String(sanitizeValue(endereco["complemento"])),
      "Complemento"
    ),
    45,
    b + 75
  );
  doc.setFont("Arial", "normal");
  doc.text("LOTE/UNID.", 130, b + 75);
  doc.setFont("Arial", "bold");
  if (unidade.length != 0)
    doc.text(
      convertCaseString(
        //@ts-ignore
        lote["loteCod"] + " / " + unidade["unidadeCod"],
        "Lote/Unid"
      ),
      155,
      b + 75
    );
  else
    doc.text(
      convertCaseString(lote["loteCod"] + " / " + lote["unidade"], "Lote/Unid"),
      155,
      b + 75
    );

  doc.setFont("Arial", "normal");
  doc.text("MUNICÍPIO / UF", 12, b + 80);
  doc.setFont("Arial", "bold");
  doc.text(convertCaseString("ITAJUBÁ / MG", "Município"), 45, b + 80);

  //--------------------
  doc.setFontSize(8);
  doc.setFont("Arial", "bold");
  doc.text("Informações do lote", 12, d + 117);
  doc.line(10, d + 119, 200, d + 119);

  doc.setFont("Arial", "normal");
  doc.text("ÁREA DO LOTE", 12, d + 124);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(String(lote["areaTerreno"] || "0.00"), "Área total") +
      " m²",
    45,
    d + 124
  );
  doc.setFont("Arial", "normal");
  doc.text("FRAÇÃO IDEAL", 12, d + 129);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(
      String(lote["fracaoIdealInfo"] || "0.00"),
      "Fração ideal"
    ) + " m²",
    45,
    d + 129
  );
  doc.setFont("Arial", "normal");
  doc.text("TESTADA PRINCIPAL", 12, d + 134);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(
      String(data.data.testadaPrincipal["extensao"] || "0.00"),
      "Área total"
    ) + " m²",
    45,
    d + 134
  );

  doc.rect(70, d + 122, 130, 25);
  doc.setFillColor(168, 168, 168);
  doc.rect(70, d + 122, 130, 5, "FD");
  doc.setFont("Arial", "bold");
  doc.text("CARACTERÍSTICAS DO LOTE", 75, d + 126);

  doc.setFontSize(7);
  doc.setFont("Arial", "normal");
  doc.text("OCUPAÇÃO", 75, d + 131);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(String(sanitizeValue(lote["ocupacao"])), "Ocupação"),
    98,
    d + 131
  );
  doc.setFont("Arial", "normal");
  doc.text("PATRIMÔNIO", 75, d + 136);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(String(sanitizeValue(lote["patrimonio"])), "Patrimônio"),
    98,
    d + 136
  );
  doc.setFont("Arial", "normal");
  doc.text("SITUAÇÃO", 75, d + 141);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(String(sanitizeValue(lote["situacaoLote"])), "Situação"),
    98,
    d + 141
  );
  doc.setFont("Arial", "normal");
  doc.text("PEDOLOGIA", 75, d + 146);
  doc.setFont("Arial", "bold");

  doc.text(
    convertCaseString(String(sanitizeValue(lote["pedologia"])), "Pedologia"),
    98,
    d + 146
  );
  doc.setFont("Arial", "normal");

  doc.setFont("Arial", "normal");
  doc.text("MURO / PASSEIO", 140, d + 131);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(String(sanitizeValue(lote["muro"])), "muro_passeio"),
    165,
    d + 131
  );
  doc.setFont("Arial", "normal");
  doc.text("TOPOGRAFIA", 140, d + 136);
  doc.setFont("Arial", "bold");
  doc.text(
    convertCaseString(String(sanitizeValue(lote["topografia"])), "Topografia"),
    165,
    d + 136
  );
  doc.setFont("Arial", "normal");
  doc.text("NÍVEL", 140, d + 141);
  doc.setFont("Arial", "bold");

  doc.text(
    convertCaseString(String(sanitizeValue(lote["nivel"])), "Nível"),
    165,
    d + 141
  );

  if (unidade.length != 0) {
    //--------------------
    doc.setFontSize(8);
    doc.setFont("Arial", "bold");
    doc.text("Informações da edificação", 12, e + 148);
    doc.line(10, e + 150, 200, e + 150);

    doc.setFont("Arial", "normal");
    doc.text("ÁREA PRINCIPAL", 12, e + 155);
    doc.setFont("Arial", "bold");
    doc.text(
      convertCaseString(
        //@ts-ignore
        String(
          isNaN(unidade["areaConstrucao"]) ? "-" : unidade["areaConstrucao"]
        ),
        "Área principal"
      ) + " m²",
      45,
      e + 155
    );
    doc.setFont("Arial", "normal");
    doc.text("ÁREA DEPENDENTES", 12, e + 160);
    doc.setFont("Arial", "bold");
    doc.text(
      convertCaseString(
        //@ts-ignore
        String(isNaN(unidade["areaEdicula"]) ? "-" : unidade["areaConstrucao"]),
        "Área das dependentes"
      ) + " m²",
      45,
      e + 160
    );
    doc.setFont("Arial", "normal");
    doc.text("ÁREA TOTAL", 12, e + 165);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(
          isNaN(unidade["areaConstrucao"]) ? "-" : unidade["areaConstrucao"]
        ),
        "Área total"
      ) + " m²",
      45,
      e + 165
    );

    doc.rect(70, e + 153, 130, 45);
    doc.setFillColor(168, 168, 168);
    doc.rect(70, e + 153, 130, 5, "FD");
    doc.setFont("Arial", "bold");
    doc.text("CARACTERÍSTICAS DA EDIFICAÇÃO", 75, e + 157);

    doc.setFontSize(7);
    doc.setFont("Arial", "normal");
    doc.text("UTILIZAÇÃO", 75, e + 162);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["utilizacao"])),
        "Utilização"
      ),
      98,
      e + 162
    );
    doc.setFont("Arial", "normal");
    doc.text("TIPO", 75, e + 167);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(String(sanitizeValue(unidade["tipo"])), "Tipo"),
      98,
      e + 167
    );
    doc.setFont("Arial", "normal");
    doc.text("ALINHAMENTO", 75, e + 172);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["alinhamento"])),
        "Situação"
      ),
      98,
      e + 172
    );
    doc.setFont("Arial", "normal");
    doc.text("POSIÇÃO", 75, e + 177);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(String(sanitizeValue(unidade["posicao"])), "Situação"),
      98,
      e + 177
    );
    doc.setFont("Arial", "normal");
    doc.text("CLASSIFICAÇÃO", 75, e + 182);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["classificacao"])),
        "Situação"
      ),
      98,
      e + 182
    );
    doc.setFont("Arial", "normal");
    doc.text("ESTRUTURA", 75, e + 187);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["estrutura"])),
        "Situação"
      ),
      98,
      e + 187
    );
    doc.setFont("Arial", "normal");
    doc.text("COBERTURA", 75, e + 192);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["cobertura"])),
        "Situação"
      ),
      98,
      e + 192
    );
    doc.setFont("Arial", "normal");
    doc.text("INST. ELÉTRICA", 75, e + 197);
    doc.setFont("Arial", "bold");
    doc.text(
      convertCaseString(
        //@ts-ignore
        String(sanitizeValue(unidade["instEletrica"])),
        "Intalação elétrica"
      ),
      98,
      e + 197
    );

    doc.setFont("Arial", "normal");
    doc.text("CONSERVAÇÃO", 140, e + 167);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["conservacao"])),
        "Patrimônio"
      ),
      165,
      e + 167
    );
    doc.setFont("Arial", "normal");
    doc.text("ACESSO", 140, e + 172);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(String(sanitizeValue(unidade["acesso"])), "Situação"),
      165,
      e + 172
    );
    doc.setFont("Arial", "normal");
    doc.text("ESQUADRIAS", 140, e + 177);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["esquadrias"])),
        "Situação"
      ),
      165,
      e + 177
    );
    doc.setFont("Arial", "normal");
    doc.text("PISO EXTERNO", 140, e + 182);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(String(sanitizeValue(unidade["piso"])), "Situação"),
      165,
      e + 182
    );
    doc.setFont("Arial", "normal");
    doc.text("REVEST. EXTERNO", 140, e + 187);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["revestimento"])),
        "Situação"
      ),
      165,
      e + 187
    );
    doc.setFont("Arial", "normal");
    doc.text("INST. SANITÁRIA", 140, e + 192);
    doc.setFont("Arial", "bold");
    doc.text(
      //@ts-ignore
      convertCaseString(
        String(sanitizeValue(unidade["instSanitaria"])),
        "Situação"
      ),
      165,
      e + 192
    );
  }
  createFooter();

  // Função para baixar a imagem como Blob
  const baixarImagem = async (url) => {
    const response = await axios.get(url, { responseType: "arraybuffer" });
    return new Blob([response.data], {
      type: response.headers["content-type"],
    });
  };
  // Função para converter Blob para Base64
  const blobParaBase64 = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Checa se a opção de fotografia está ativa

  if (options?.fotografia) {
    // Adiciona as imagens ao PDF
    for (let i = 0; i < data.data.arquivos.length; i++) {
      const item = data.data.arquivos[i];
      if (item.url) {
        try {
          const imageBlob = await baixarImagem(item.url);
          const base64Image = await blobParaBase64(imageBlob);

          // Adiciona uma nova página antes de cada imagem
          if (i >= 0) {
            doc.addPage();
            createHeader();
          }

          // Adiciona um título para a imagem
          doc.setFontSize(14);
          doc.text(`Imagem ${item.id}`, 10, 40);

          // Adiciona a imagem
          doc.addImage(
            //@ts-ignore
            base64Image,
            "JPEG",
            10,
            50,
            180,
            100
          );
          createFooter();
        } catch (error) {
          console.log("🚀 ~ generatePdf ~ error:", error);
        }
      }
    }
  }

  if (options?.planta) {
    try {
      const mapImage = await generateMapImage(data);
      doc.addPage();
      createHeader();
      doc.setFontSize(14);
      doc.setFont("Arial", "bold");
      doc.text("Planta", 105, 40, { align: "center" });
      if (mapImage) doc.addImage(mapImage, "PNG", 15, 45, 180, 100);

      // Adiciona a legenda
      doc.setFontSize(8);
      doc.setFont("Arial", "bold");
      doc.text("Legenda", 15, 160);

      // Array com os itens da legenda
      const legendItems = [
        { color: "#FEEA37", text: "Lote" }, // rgba(254,234,55,0.5) -> hexadecimal sem transparência
        { color: "#808080", text: "Cobertura" }, // rgba(128,128,128,0.3) -> hexadecimal sem transparência
        { color: "#0000FF", text: "Testada" }, // 'blue' -> hexadecimal
        // { color: "#E900AC", text: "Lotes da quadra" }, // rgba(233,0,172,0.2) -> hexadecimal sem transparência
        // { color: "#E966AC", text: "Quadra" }, // já estava em hexadecimal
      ];
      // Espaçamento vertical entre os itens da legenda
      const verticalSpacing = 8;

      // Adiciona os itens da legenda
      legendItems.forEach((item, index) => {
        const yPosition = 162 + index * verticalSpacing;
        doc.setFillColor(item.color);
        if (item.text === "Testada") {
          doc.rect(15, yPosition, 1, 5, "F");
        } else {
          doc.rect(15, yPosition, 5, 5, "F");
        }
        doc.setFont("Arial", "normal");
        doc.text(item.text, 23, yPosition + 4); // Texto alinhado verticalmente ao centro do quadrado
      });
      createFooter();
    } catch (error) {
      console.error("Erro ao adicionar mapa ao PDF:", error);
    }
  }

  doc.save("bic.pdf");
};

const resolutions = [
  2785.522278112486, 1392.761139056243, 696.3805695281216, 348.1902847640608,
  174.0951423820304, 87.0475711910152, 43.5237855955076, 21.7618927977538,
  10.8809463988769, 5.44047319943845, 2.720236599719225, 1.3601182998596124,
  0.6800591499298062, 0.3400295749649031, 0.1700147874824516,
  0.0850073937412258, 0.0425036968706129, 0.0212518484353064,
  0.0106259242176532, 0.0053129621088266, 0.0026564810544133,
];
