import { Feature } from "ol";
import { MultiPolygon, Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import { getArea } from "ol/sphere";  // Corrigido para usar getArea()

export function fnAddAreaLabel(source) {
    // Obtendo o centro da extensão do source
    const coordCenter = source.getExtent();
    const center = [
        (coordCenter[0] + coordCenter[2]) / 2,
        (coordCenter[1] + coordCenter[3]) / 2,
    ];

    // Calculando a área do MultiPolygon com base na geometria
    const geometry = source.getFeatures()[0].getGeometry();
    const area = geometry.getArea();  // Usando getArea() para calcular a área corretamente

    // Criando a camada com o texto da área
    const labelArea = new VectorLayer({
        source: new VectorSource({
            features: [
                new Feature({
                    geometry: new Point(center),
                    name: `${area.toFixed(2)} m²`,  // Exibindo a área com 2 casas decimais
                }),
            ],
        }),
        style: new Style({
            text: new Text({
                text: `${area.toFixed(2)} m²`,  // Exibindo a área com 2 casas decimais
                font: "18px Calibri, sans-serif",
                fill: new Fill({
                    color: "#0000ff",
                }),
                stroke: new Stroke({
                    color: "#fff",
                    width: 3,
                }),
            }),
        }),
    });

    return labelArea;
}
