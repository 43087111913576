import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Buttons from "../Buttons/Buttons";
import FormHelperText from "@mui/material/FormHelperText";
import { NumericFormat } from "react-number-format";
import InconsistenciaServices from "../../../../../services/inconsistenciaServices";
import "./Form.css";

const services = new InconsistenciaServices();

const Form = ({
  results,
  setResults,
  loading,
  setLoading,
  setIsSearch,
  showPrint,
  showSend,
  showCheck,
  onHelp,
  onPrint,
  onCheck,
}) => {
  const [selectedLocal, setSelectedLocal] = useState("");
  const [selectedRule, setSelectedRule] = useState("");

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    clearErrors,
  } = form;

  const handleLocalChange = (e) => {
    setSelectedLocal(e.target.value);
    clearErrors("local");

    clearErrors(["district", "lot", "property"]);
    setValue("district", null);
    setValue("lot", "");
    setValue("property", "");

    formSchema.shape.district = z.enum("").nullable().optional();
    formSchema.shape.lot = z.string().nullable().optional();
    formSchema.shape.property = z.string().nullable().optional();

    switch (e.target.value) {
      case "district":
        formSchema.shape.district = z.enum(allDistricts, {
          message: "Selecione um bairro",
        });
        break;
      case "lot":
        formSchema.shape.lot = z.string().nonempty({
          message: "Informe o identificador do lote",
        });
        break;
      case "property":
        formSchema.shape.property = z.string().nonempty({
          message: "Informe o identificador do imóvel",
        });
        break;
      default:
        break;
    }
  };

  const handleRuleChange = (e) => {
    setSelectedRule(e.target.value);
    clearErrors(["rule", "minArea", "maxArea"]);
    setValue("minArea", 0);
    setValue("maxArea", 0);
  };

  const onSearch = (data) => {
    setLoading(true);
    setIsSearch(true);
    setResults([]);

    const rule = data.rule;

    // isAreaConstrucao
    // isAreaTerreno
    // isDemolicao
    // isNovaInscricao
    // isNovaUnidade

    console.log(data);

    services.getInconsistencias(data).then((response) => {
      console.log(response);

      if (
        !response ||
        (typeof response !== "object" && typeof response != "array")
      ) {
        alert("Erro ao buscar inconsistências");
        setLoading(false);
        return;
      }

      try {
        const newResults = response.filter((result) => {
          switch (rule) {
            case "landAreaChange":
              return result.isAreaTerreno;
            case "buildingAreaChange":
              return result.isAreaConstrucao;
            case "newUnits":
              return result.isNovaUnidade;
            case "demolition":
              return result.isDemolicao;
            case "registrationUpdate":
              return result.isNovaInscricao;
            default:
              return false;
          }
        });

        setResults(newResults);
        setLoading(false);
      } catch (error) {
        alert("Erro ao buscar inconsistências");
        setLoading(false);
        return;
      }
    });
  };

  const renderLocalInfoFields = () => {
    switch (selectedLocal) {
      case "district":
        return (
          <FormControl size="small" fullWidth error={!!errors.district}>
            <InputLabel id="district">Bairro</InputLabel>
            <Controller
              name="district"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="district"
                  value={field.value || ""}
                  onChange={(e) => field.onChange(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value="" disabled>
                    Selecione um bairro
                  </MenuItem>
                  {allDistricts.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{errors.district?.message || ""}</FormHelperText>
          </FormControl>
        );
      case "lot":
        return (
          <Controller
            name="lot"
            control={control}
            render={({ field }) => (
              <TextField
                label="Lote"
                fullWidth
                value={field.value || ""}
                size="small"
                onChange={field.onChange}
                error={!!errors.lot}
                helperText={errors.lot?.message || ""}
              />
            )}
          />
        );
      case "property":
        return (
          <Controller
            name="property"
            control={control}
            render={({ field }) => (
              <TextField
                label="Imóvel"
                fullWidth
                value={field.value || ""}
                size="small"
                onChange={field.onChange}
                error={!!errors.property}
                helperText={errors.property?.message || ""}
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  const renderRuleFields = () =>
    (selectedRule === "landAreaChange" ||
      selectedRule === "buildingAreaChange") && (
      <div className="area">
        <Controller
          name="minArea"
          control={control}
          render={({ field }) => (
            <NumericFormat
              label="Área mínima"
              fullWidth
              value={field.value || ""}
              size="small"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              customInput={TextField}
              onValueChange={(values) => field.onChange(values.floatValue)}
              error={!!errors.minArea}
              helperText={errors.minArea?.message || ""}
            />
          )}
        />
        <Controller
          name="maxArea"
          control={control}
          render={({ field }) => (
            <NumericFormat
              label="Área máxima"
              fullWidth
              value={field.value || ""}
              size="small"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              customInput={TextField}
              onValueChange={(values) => field.onChange(values.floatValue)}
              error={!!errors.maxArea}
              helperText={errors.maxArea?.message || ""}
            />
          )}
        />
      </div>
    );

  return (
    <>
      <Buttons
        loading={loading}
        showPrint={showPrint}
        showSend={showSend}
        showCheck={showCheck}
        onSearch={handleSubmit(onSearch)}
        onPrint={onPrint}
        onCheck={onCheck}
        onHelp={onHelp}
      />
      <form className="form">
        <div className="fields">
          <FormControl size="small" fullWidth error={!!errors.local}>
            <InputLabel id="local">Local</InputLabel>
            <Controller
              name="local"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="local"
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleLocalChange(e);
                  }}
                  disabled={loading}
                >
                  <MenuItem value="" disabled>
                    Selecione um local
                  </MenuItem>
                  {locals.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{errors.local?.message || ""}</FormHelperText>
          </FormControl>

          {renderLocalInfoFields()}

          <FormControl size="small" fullWidth error={!!errors.rule}>
            <InputLabel id="rule">Regra</InputLabel>
            <Controller
              name="rule"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="rule"
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleRuleChange(e);
                  }}
                  disabled={loading}
                >
                  <MenuItem value="" disabled>
                    Selecione uma regra
                  </MenuItem>
                  {rules.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{errors.rule?.message || ""}</FormHelperText>
          </FormControl>

          {renderRuleFields()}
        </div>
      </form>
    </>
  );
};

export default Form;

const allDistricts = [
  "ACUDE",
  "ACUDE / DISTRITO INDUSTRIAL",
  "ANHUMAS",
  "ANO BOM",
  "AVENIDA",
  "BARBOSA",
  "BOA VISTA",
  "BPS",
  "BURACAO",
  "CAFONA",
  "CANTA GALO",
  "CAPETINGA",
  "CAPITUBA",
  "CENTRO",
  "CRUZEIRO",
  "DISTRITO INDUSTRIAL",
  "DISTRITO INDUSTRIAL III",
  "DO IPE",
  "DOS MARINS",
  "ENCRUZO",
  "ESPIGAO",
  "ESTANCIA",
  "ESTIVA",
  "ESTRADA MARIA DA FE",
  "FAZENDINHA",
  "FIGUEIRAS",
  "ILHEUS",
  "IMBEL",
  "JARDIM BERNADETE",
  "JARDIM DAS COLINAS",
  "JARDIM DAS PALMEIRAS",
  "JURU",
  "LOTEAMENTO BAHAMAS",
  "LOTEAMENTO SAPUCAI",
  "LOURENÇO VELHO",
  "MEDICINA",
  "MOQUEM",
  "MORRO CHIC",
  "MORRO GRANDE",
  "NACOES",
  "NOSSA SENHORA DA AGONIA",
  "NOSSA SENHORA DE FATIMA",
  "NOSSA SENHORA DE LOURDES",
  "NOVO HORIZONTE",
  "ORIENTE",
  "PEDRA MAMONA",
  "PEDRA PRETA",
  "PEROBA",
  "PESSEGUEIRO",
  "PIEDADE",
  "PINHEIRINHO",
  "PONTE ALTA",
  "PONTE SANTO ANTONIO",
  "PORTO VELHO",
  "RANCHO GRANDE",
  "REBOURGEON",
  "RETIRO",
  "RIO MANSO",
  "SABARA",
  "SANTA CRUZ",
  "SANTA HELENA",
  "SANTA LUZIA",
  "SANTA RITA DE CASSIA",
  "SANTA ROSA",
  "SANTO ANTONIO",
  "SANTO ANTONIO / SANTA LUZIA",
  "SANTOS DUMONT",
  "SAO JOSE DO RIO MANSO",
  "SAO JUDAS TADEU",
  "SAO PEDRO",
  "SAO SEBASTIAO",
  "SAO VICENTE",
  "SERRA DOS TOLEDOS",
  "VARGINHA",
  "VARGINHA / PORTO VELHO",
  "VILA BETEL",
  "VILA ISABEL",
  "VILA PODDIS",
  "VILA RUBENS",
  "VILA RUBENS / VILA ISABEL",
];

const locals = [
  {
    value: "allCity",
    label: "Toda a cidade",
  },
  {
    value: "district",
    label: "Bairro",
  },
  {
    value: "lot",
    label: "Lote",
  },
  {
    value: "property",
    label: "Imóvel",
  },
];

const rules = [
  {
    value: "landAreaChange",
    label: "Alteração de área de terreno",
  },
  {
    value: "buildingAreaChange",
    label: "Alteração de área construída",
  },
  {
    value: "newUnits",
    label: "Novas unidades",
  },
  {
    value: "demolition",
    label: "Demolição",
  },
  {
    value: "registrationUpdate",
    label: "Atualização de cadastro",
  },
];

const formSchema = z.object({
  local: z.enum(
    locals.map((local) => local.value),
    {
      message: "Selecione um local",
    }
  ),
  district: z.enum(allDistricts).nullable().optional(),
  lot: z.string().optional(),
  property: z.string().optional(),
  rule: z.enum(
    rules.map((rule) => rule.value),
    {
      message: "Selecione uma regra",
    }
  ),
  minArea: z.number().nullable().optional(),
  maxArea: z.number().nullable().optional(),
});
