import { View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Projection } from "ol/proj";
import { OSM, TileWMS } from "ol/source";
import WMTSTileGrid from "ol/tilegrid/WMTS";

export class ConfigTile {
  // constructor() {}

  caxambuMapInfos = {
    projectionName: "EPSG:31983",
    projetionUnits: "m",
    projetionAxisOrientation: "neu",
    mapCenter: [452057.91224, 7519740.85876],
    prefectureCoord: [506758.566572082, 7569515.582740938],
    mapZoom: {
      min: 0,
      max: 8,
      zoom: 1,
    },
    tileURL: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}${process.env.REACT_APP_API_GEOSERVER_WMTS_PATH}`,
    tileLayer: "ITAJUBA:ITA_ORTO_5CM_GEOTIFF-WEB",
    titleFormat: "image/png",
    tileGridSize: [512, 512],
    tileGridExtent: [
      446826.84914465, 7515831.60890135, 457626.84914465, 7524081.60890135,
    ],
    tileGridOrigin: [446826.84914465, 7524081.60890135],
    tileResolutions: [
      13.999999999999998, 6.999999999999999, 3.4999999999999996,
      1.7499999999999998, 0.8749999999999999, 0.4374999999999999, 0.21875,
      0.109375, 0.0546875,
    ],
    tileMatrixIds: [
      "EPSG:31983:0",
      "EPSG:31983:1",
      "EPSG:31983:2",
      "EPSG:31983:3",
      "EPSG:31983:4",
      "EPSG:31983:5",
      "EPSG:31983:6",
      "EPSG:31983:7",
      "EPSG:31983:8",
    ],

    vectorSourceUrl: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}${process.env.REACT_APP_API_STREETS_GEOSERVER_PATH}`,
    tilesWMS: {
      url: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}${process.env.REACT_APP_API_GEOSERVER_WORKSPACE_PATH}`,
      params: {
        LAYERS: `${process.env.REACT_APP_GEOSERVER_WORKSPACE}:`,
        tilesOrigin: {
          first: 446826.84914465,
          second: 7515831.60890135,
          final: "446826.84914465,7515831.60890135",
        },
        VERSION: "1.1.1",
        format: "image/png",
        tiled: true,
      },
    },
  };

  projectionCaxambuByGeoserver = new Projection({
    code: this.caxambuMapInfos.projectionName,
    units: this.caxambuMapInfos.projetionUnits,
    axisOrientation: this.caxambuMapInfos.projetionAxisOrientation,
  });

  sourceCaxambuByGeoserver = new TileWMS({
    url: this.caxambuMapInfos.tilesWMS.url,
    params: {
      LAYERS: this.caxambuMapInfos.tileLayer,
      FORMAT: this.caxambuMapInfos.tilesWMS.params.format,
      TILED: this.caxambuMapInfos.tilesWMS.params.tiled,
      VERSION: this.caxambuMapInfos.tilesWMS.params.VERSION,
    },
    serverType: "geoserver",
    cacheSize: 4096,
    tileGrid: new WMTSTileGrid({
      tileSize: this.caxambuMapInfos.tileGridSize,
      extent: this.caxambuMapInfos.tileGridExtent,
      origin: this.caxambuMapInfos.tileGridOrigin,
      resolutions: this.caxambuMapInfos.tileResolutions,
      matrixIds: this.caxambuMapInfos.tileMatrixIds,
    }),
    projection: this.projectionCaxambuByGeoserver,
    wrapX: true,
  });

  // View do mapa de Caxambu do GeoServer
  viewCaxambuByGeoserver = new View({
    center: this.caxambuMapInfos.mapCenter,
    zoom: this.caxambuMapInfos.mapZoom.zoom,
    minZoom: this.caxambuMapInfos.mapZoom.min,
    maxZoom: this.caxambuMapInfos.mapZoom.max,
    resolutions: this.caxambuMapInfos.tileResolutions,
    projection: this.projectionCaxambuByGeoserver,
    smoothExtentConstraint: true,
    extent: this.caxambuMapInfos.tileGridExtent,
    smoothResolutionConstraint: true,
  });

  caxambuMapInfoOSM = {
    center: [-5059741.005886804, -2562504.944493513],
    zoom: 15,
    minZoom: 13,
  };

  viewOSM = new View({
    center: this.caxambuMapInfoOSM.center,
    zoom: this.caxambuMapInfoOSM.zoom,
    minZoom: this.caxambuMapInfoOSM.minZoom,
  });

  tileOSM = new TileLayer({
    source: new OSM(),
    zIndex: 8,
  });

  getSource() {
    return this.sourceCaxambuByGeoserver;
  }

  getExtent() {
    return this.caxambuMapInfos.tileGridExtent;
  }
  
    getView() {
      return this.viewCaxambuByGeoserver;
    }
  
    getViewOSM() {
      return this.viewCaxambuByGeoserver;
    }
  
    getTileOSM() {
      return this.tileOSM;
    }
}
