import { Feature } from "ol";
import { MultiPolygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { getLength as getLineLength } from "ol/sphere";
import { Fill, Stroke, Style } from "ol/style";
import Point from "ol/geom/Point";
import { GeoJSON } from "ol/format";

export const mapLote = (source) => {
    const vectorLayer = new VectorLayer({
        source: source,
        style: new Style({
            fill: new Fill({
                color: "rgba(254,234,55,1)",
            }),
            stroke: new Stroke({
                color: "#0a0a0a",
                width: 1,
            }),
        }),
    });

    return vectorLayer
}

export const createLoteVizinho = (source) => {
    const vectorLayer = new VectorLayer({
        source: source,
        style: new Style({
            fill: new Fill({
                color: "rgba(128,128,128,0.3)",
            }),
            stroke: new Stroke({
                color: "#0a0a0a",
                width: 1,
            }),
            zIndex: 2,
        }),
    });
    return vectorLayer
};

export const createSource = (data) => {

    const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(data, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
        }),
    });

    return newSource
}