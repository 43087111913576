import { CardContent, CardMedia, Grid, IconButton } from "@mui/material"
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

export const UplaodsInit = ({ images, removeUpload }) => {
    return (
        <>
            {images.map((imagemUploads, index) => (
                <Grid item xs={12} sm={6} md={4} >
                    <CardContent sx={{
                        height: 135,
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '2px dashed #ccc',
                        borderRadius: '4px',
                        boxSizing: 'border-box',
                        '&:last-child': { paddingBottom: '16px' },
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#f5f5f5',
                        },
                    }}>
                        <IconButton aria-label="delete" size="small" style={{ position: 'absolute', top: 5, right: 5, padding: 0, minWidth: 0 }}
                            onClick={() => removeUpload(imagemUploads.id)}>
                            <DoNotDisturbOnIcon fontSize="inherit" />
                        </IconButton>

                        <CardMedia
                            component="img"
                            height="95"
                            image={imagemUploads.url}
                            alt={`Imagem ${index + 1}`}
                            sx={{
                                objectFit: 'contain',
                            }}
                        />
                    </CardContent>
                </Grid>
            ))}
        </>
    )
}