import React, { useEffect, useRef, useState } from "react";

import { Box, Card, FormControlLabel, Switch } from "@mui/material";
import "./ColunaMapa.css";
import { configurarCoordenadaCentral } from "../../../../Map/Hooks/FormaterCoordinates";
import ManegerVectorLayer from "../../../../Map/Component/VectorManager";
import { getLogradouroLayer } from "../../../../hooks/logradouroMap";

export function ColunaMapa({ responseAdversidade }) {
  const [state, setState] = useState({
    Lote: true,
    Testada: true,
    LotesVizinhos: true,
    Cobertura: true,
  });
  const [testadasArray, setTestadasArray] = useState([]);
  const vectorManeger = useRef(null);
  const mapManger = useRef(null);
  const vecOlUidRef = React.useRef({}); //logradouro map

  const handleChange = (event) => {
    const { name, checked } = event.target;

    setState((prevState) => {
      // Atualizar o estado com o novo valor do checkbox
      const updatedState = { ...prevState, [name]: checked };

      // Obter as chaves com `true`
      const keysWithTrue = Object.entries(updatedState)
        .filter(([key, value]) => value) // Filtra os pares onde o valor é `true`
        .map(([key]) => key); // Extrai apenas as chaves

      // Obter as chaves com `false`
      const keysWithFalse = Object.entries(updatedState)
        .filter(([key, value]) => !value) // Filtra os pares onde o valor é `false`
        .map(([key]) => key); // Extrai apenas as chaves

      // Remover camadas dos itens que agora são `false`
      keysWithFalse.forEach((item) => {
        vectorManeger.current.removerVectorLayer(item);
      });

      // Adicionar camadas dos itens que agora são `true`
      keysWithTrue.forEach((item) => {
        vectorManeger.current.adicionarVectorLayer(item);
      });

      // Retornar o estado atualizado
      return updatedState;
    });
  };

  useEffect(() => {
    const configurarMapa = async () => {
      try {
        const coordendaCentral = configurarCoordenadaCentral(
          responseAdversidade.pontoCentral[0].geom.coordinates
        );
        if (!vectorManeger.current) {
          const lote = {
            coordenadas: responseAdversidade.adversidade.geom.coordinates,
          };
          const testada = responseAdversidade.testadas.map((item) => ({
            coordenadas: [item.geom.coordinates],
            id: {
              id: item.id,
              logradouroNome: item.eixoProximo.logradouronome,
            },
          }));
          const loteVizinho = responseAdversidade.lotesV.map((item) => ({
            coordenadas: item.geom.coordinates,
          }));
          const cobertura = responseAdversidade.coberturas.map((item) => ({
            coordenadas: [item.geom.coordinates],
          }));

          vectorManeger.current = new ManegerVectorLayer(coordendaCentral);
          vectorManeger.current.setMapElement(mapManger.current);

          vectorManeger.current.iniciarVectorLayer("Lote", lote);
          vectorManeger.current.iniciarVectorLayer("Testada", testada);
          vectorManeger.current.iniciarVectorLayer(
            "LotesVizinhos",
            loteVizinho
          );
          vectorManeger.current.iniciarVectorLayer("Cobertura", cobertura);
          vectorManeger.current.initBoudingBox("Lote");

          const dadosCobertura = vectorManeger.current
            .getLayersMap("Testada")
            .map((item) => item.DADOS);
          setTestadasArray(dadosCobertura[0]);

          getLogradouroLayer(vectorManeger, vecOlUidRef);
        }
      } catch (error) {
        console.error("Erro ao obter dados de coleta:", error);
      }
    };

    configurarMapa();
  }, []);

  return (
    <div
      style={{ width: "100%", flex: 1 }}
      className='MesmoLote-vectorization-container'
    >
      <div className='MesmoLote-vectorization-header'>
        <div className='MesmoLote-vectorization-content'>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              width: "100%",
              bgcolor: "background.paper",
              border: "1px solid black",
              padding: "10px",
              color: "text.secondary",
              "& svg": { m: 0.9 },
              "& hr": { mx: 0.9 },
              maxWidth: "400px",
            }}
          >
            <div className='vectorization-switch-container'>
              <h5 className='vectorization-title'>
                GEOMETRIAS IDENTIFICADA NA VETORIZAÇÃO:
              </h5>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.Lote}
                    onChange={handleChange}
                    name='Lote'
                  />
                }
                label='Mostrar lotes'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.Testada}
                    onChange={handleChange}
                    name='Testada'
                  />
                }
                label='Mostrar testadas'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.LotesVizinhos}
                    onChange={handleChange}
                    name='LotesVizinhos'
                  />
                }
                label='Mostrar lotes vizinhos'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={state.Cobertura}
                    onChange={handleChange}
                    name='Cobertura'
                  />
                }
                label='Mostrar área coberta'
              />
            </div>
          </Box>

          <div className='MesmoLote-vectorization-info-box'>
            <div style={{ background: "rgba(19,100,181, 1)" }} />
            <h5>Lote</h5>
          </div>
          <div className='MesmoLote-vectorization-info-box'>
            <div style={{ background: "rgba(166,166,166, 1)" }} />
            <h5>Lotes Vizinhos</h5>
          </div>

          {testadasArray.map((testada) => {
            return (
              <div
                className='AdvMapa-vectorization-info-box'
                key={testada.dados.id}
              >
                <div
                  style={{
                    background: testada.color,
                    height: "20px",
                    width: "20px",
                  }}
                />
                <h5>{testada.dados.logradouroNome}</h5>
              </div>
            );
          })}
        </div>
        <Card variant='outlined' sx={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <div className='MesmoLote-vectorization-map-container'>
                <div ref={mapManger} className='MesmoLote-vectorization-map' />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
