import React, { useRef, useState, useEffect, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  faArrowLeft,
  faPrint,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapRender from "./MapRender/MapRender";

import { register } from "ol/proj/proj4.js";
import proj4 from "proj4";

import logo from "../../../assets/img/logo_ita.png";
import daclogo from "../../../assets/img/logo.png";
import "./BicPlanta.css";

import { Api } from "../../../services/Api";

proj4.defs(
  "EPSG:31983",
  "+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

function BicPlanta({ id, setSelectedResult }) {
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const componentRef = useRef();
  function handleClickPrint() {
    setPrint((prevState) => !prevState);
    handlePrint();
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [valuesLat, setValuesLat] = useState([0, 0, 0, 0]);
  const [valuesLon, setValuesLon] = useState([0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);

    Api.numeroInscricaoPost({ codigoImovel: id }).then((response) => {
      if (typeof response !== "object") {
        setResult(undefined);
        setLoading(false);
        return;
      }

      setResult(response.data.result);
      setLoading(false);
    });
  }, [id]);

  return (
    <div className='result'>
      <div className='actions'>
        <button
          className='action-button'
          onClick={() => setSelectedResult(undefined)}
          style={{
            marginRight: "auto",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          Voltar
        </button>
        {!loading && result && (
          <button
            className='action-button'
            onClick={handleClickPrint}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faPrint} />
            Imprimir
          </button>
        )}
      </div>

      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "100px 0px",
          }}
        >
          <FontAwesomeIcon size='3x' icon={faSpinner} color='#1976d2' spin />
        </div>
      )}

      {!loading && result && (
        <ComponentToPrint
          ref={componentRef}
          result={result}
          valuesLat={valuesLat}
          setValuesLat={setValuesLat}
          valuesLon={valuesLon}
          setValuesLon={setValuesLon}
          print={print}
        />
      )}

      {!loading && !result && (
        <div className='result-content'>
          <h1
            className='title'
            style={{
              color: "red",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Erro ao carregar o resultado
          </h1>
          <p
            className='description'
            style={{
              color: "red",
              textAlign: "center",
            }}
          >
            Houve um erro ao carregar o resultado. Por favor, tente novamente.
          </p>
        </div>
      )}
    </div>
  );
}

const ComponentToPrint = forwardRef((props, ref) => {
  const { result, print } = props;

  const date = new Date();
  const today = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    }/${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
    }:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;

  return (
    <div className='page' ref={ref}>

      <div className='page-header'>

        <div className='description'>
          <p className='text'>PREFEITURA MUNICIPAL DE ITAJUBÁ</p>
          <p className='text'>Emissão: {today}</p>
        </div>

        <div className='content'>
          <img src={logo} alt='logo' width='80' height='80' />
          <div className='title'>
            <h1 className='text'>PREFEITURA MUNICIPAL DE ITAJUBÁ</h1>
            <h2 className='text'>ESTADO DE MINAS GERAIS</h2>
          </div>
        </div>
      </div>


      <div className='page-content'>
        <div className='preview'>
          <div className='title'>
            <img src={logo} alt='logo' width='40' height='40' />

            <div className='text'>
              <p className='text'>Planta</p>
            </div>
          </div>

          <div className='maps'>
            <div className='map'>
              <div className='values'>
                <p className='subtitle'>{props.valuesLat[0]}</p>
                <p className='subtitle'>{props.valuesLat[1]}</p>
                <p className='subtitle'>{props.valuesLat[2]}</p>
                <p className='subtitle'>{props.valuesLat[3]}</p>
              </div>
              <div className='values'>
                <p className='subtitle'>{props.valuesLon[0]}</p>
                <p className='subtitle'>{props.valuesLon[1]}</p>
                <p className='subtitle'>{props.valuesLon[2]}</p>
                <p className='subtitle'>{props.valuesLon[3]}</p>
                <p className='subtitle'>{props.valuesLon[4]}</p>
                <p className='subtitle'>{props.valuesLon[5]}</p>
              </div>
              <div style={{ width: '100%', height: '100%' }}>
                <MapRender
                  result={result}
                  number='1'
                  setValuesLat={props.setValuesLat}
                  setValuesLon={props.setValuesLon}
                  print={print}
                />
              </div>
            </div>
            {/* <div className='map'>
            <MapRender result={result} number='2' print={print} />
          </div>
          <div className='map'>
            <MapRender result={result} number='3' print={print} />
          </div> */}
          </div>

          <div className='footer'>
            <div className='information'>
              {/* <img src={image} alt="image" width="150" /> */}

              <div className='logos'></div>
              <h3 className='text'>Legenda</h3>
              <div
                style={{
                  width: "100%",
                  border: "1px solid black",
                  padding: "2px",
                  textAlign: "justify",
                }}
              >
                <ul>
                  <li>
                    <span
                      style={{
                        background: "rgba(254,234,55,0.5)",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Lote
                  </li>
                  <li>
                    <span
                      style={{
                        background: "rgba(128,128,128,0.3)",
                        display: "inline-block",
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Cobertura
                  </li>
                  <li>
                    <span
                      style={{
                        background: "blue",
                        display: "inline-block",
                        width: "2px",
                        height: "10px",
                        marginRight: "5px",
                      }}
                    ></span>
                    Testada
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div style={{ width: '100%', position: "absolute", padding: 25, bottom: 0 }}>
        <div className='page-header' >
          <div className='description'>
            <p className='text'>{result.endereco.logradouro.nome + ", " + result.endereco.numero}</p>
            <p className='text'>Emissão: {today}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BicPlanta;
