export const itajubaMapInfos = {
  vectorSourceUrl: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}/${process.env.REACT_APP_API_STREETS_GEOSERVER_PATH}`,
  tilesWMS: {
    url: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}/${process.env.REACT_APP_API_GEOSERVER_WORKSPACE_PATH}`,
    params: {
      LAYERS: `${process.env.REACT_APP_GEOSERVER_WORKSPACE}:`,
      tilesOrigin: {
        first: 446826.84914465,
        second: 7515831.60890135,
        final: "446826.84914465,7515831.60890135",
      },
      VERSION: "1.1.1",
      format: "image/png",
      tiled: true,
    },
  },
};
