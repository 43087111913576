import LayerObserver from "./LayerObserver";
import MapManager from "./MapManager";

class ManegerVectorLayer {
  constructor(pontoCentral) {
    this.manager = new MapManager(pontoCentral);
    this.vectorManager = new LayerObserver(this.manager);
    this.mapElement = null; // Inicializa a variável para armazenar o ref
    this.manager.setTarget(this.mapElement);
  }

  // Coloca uma cor de base caso não seja a camada padrão
  setColorBase(color) {
    this.vectorManager.setColor(color);
  }

  // FUNÇÕES PARA GERENCIAMENTO DA LAYER
  adicionarVectorLayer = (type) => {
    if (this.vectorManager) {
      this.vectorManager.addVectorLayer(type);
    }
  };

  removerVectorLayer = (type) => {
    if (this.vectorManager) {
      this.vectorManager.removeVectorLayer(type);
    }
  };

  desativeInteraction = (type) => {
    this.vectorManager.removeInteractionVector(type);
  };

  reactiveInteraction = (type) => {
    this.vectorManager.activeInteractionVector(type);
  };

  getLayersMap = (type) => {
    const layersCurrentMap = this.vectorManager.getLayers(type);
    return layersCurrentMap;
  };

  getLayersRemovidaMap = (type) => {
    const layersCurrentMap = this.vectorManager.getLayersRemoved(type);
    return layersCurrentMap;
  };

  iniciarVectorLayer = (
    type,
    coordenada,
    funcao = false,
    functionEdit = null
  ) => {
    if (this.vectorManager && coordenada) {
      this.vectorManager.newVector(type, coordenada, funcao, functionEdit);
    }
  };

  setZoomElement = (zoom) => {
    this.manager.setZoom(zoom);
  };

  setCenterElement = (ponto) => {
    this.manager.setCenter(ponto);
  };

  setMapElement = (element) => {
    this.mapElement = element; // Armazena o elemento do DOM
    if (this.mapElement) {
      this.manager.setTarget(this.mapElement); // Define o alvo do mapa
    }
  };

  addCursorPointer = () => {
    this.manager.getMap().on("pointermove", function (event) {
      var hit = this.forEachFeatureAtPixel(event.pixel, (f) => {
        return f.values_?.ID?.cursorPointer || false;
      });
      hit
        ? (this.getTargetElement().style.cursor = "pointer")
        : (this.getTargetElement().style.cursor = "");
    });
  };

  initBoudingBox = (type) => {
    const layers = this.getLayersMap(type);
    const extent = layers[layers.length - 1].BOUDINGBOX;
    //   // Certifique-se de que a extensão não está vazia
    if (!extent.every(Number.isFinite)) {
      console.warn("Nenhuma feição encontrada na camada.");
    } else {
      this.manager.setBoudingBox(extent);
    }
  };

  resetLayers = (type) => {
    const arrayLayers = this.manager
      .getLayers()
      .filter((item) => item.TYPE === type);
    arrayLayers.slice(0, -1).forEach((item) => this.manager.removeLayer(item));
  };

  resetInt = () => {
    this.manager.resetInteractions();
  };

  addLayer(layer) {
    this.manager.addLayer(layer);
  }
}

export default ManegerVectorLayer;
