const url = process.env.REACT_APP_API;

export default class InconsistenciaServices {
  async getInconsistencias(body) {
    try {
      const response = await fetch(url + "/inconsistencias", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  }

  async getInconsistencia(id) {
    try {
      const response = await fetch(url + "/inconsistencias/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.log(error);

      return error;
    }
  }

  async markAsPrinted(ids) {
    console.log(ids);
  }
}
