import React from "react";
import { Colors } from "../../../../Map/Component/Colors";

type Props = {
  indexColor: number;
  name: string;
};

export function TestadaLabel({ indexColor, name }: Props) {
  const initColors = () => {
    const obj = new Colors()
    const arrayTestada = obj.getColors()
    return arrayTestada
  }
  const colorsTestadas = initColors().testada || []

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          background: colorsTestadas[indexColor],
          height: "20px",
          width: "20px",
          display: "inline-block",
          marginRight: "8px",
        }}
      />

      {name}
    </div>
  );
}
